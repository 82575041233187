import { ClientError, GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';

import { UpdateOrderDocument as UpdateAddresDocument } from '@global/components/templates/OrderEditAddressModal/graphql/EditAddress.generated';
import KemiApiError, { GraphqlError } from '@global/service/Error/KemiApiError';
import { orderLogger } from '@global/service/logger/order-logger';
import { UpdateOrderDocument } from '@pages/orders/[orderId]/checkout/source/graphql/Checkout.generated';
import { isTokenCreationOperation } from 'src/auth/helper';
import { clearToken, getAccessToken } from 'src/auth/modules/kemiToken';

const isCSR = typeof window !== 'undefined';

const graphQLClient = new GraphQLClient(
  process.env.NEXT_PUBLIC_API_URL as string
);
export const fetcher = <Data, Variables>(
  query: string,
  variables?: Variables,
  options?: RequestInit['headers']
) => {
  return async () => {
    try {
      let headers = options;

      if (!headers && isCSR && !isTokenCreationOperation(query)) {
        const accessToken = await getAccessToken();

        if (accessToken) {
          headers = {
            Authorization: `Bearer ${accessToken}`,
          };
        }
      }

      return await graphQLClient.request<Data, Variables>(
        query,
        variables,
        headers
      );
    } catch (e) {
      if (query === UpdateOrderDocument || query === UpdateAddresDocument) {
        orderLogger.error(`${query.trim().split('(')[0]} error`, {
          error: e,
          variables,
        });
      }

      // 기존 kemi api error 타입 하위 호환
      if (e instanceof ClientError && e.response.errors) {
        const errorInterface: GraphqlError = e.response.errors.map((error) => {
          return {
            message: error.message,
            path: error.path || [],
            extensions: {
              code: error.extensions.code,
              exception: error.extensions.exception,
            },
            locations: error.locations || [],
          };
        });

        const hasAuthenticationFailed = errorInterface.some(
          ({ extensions }) => extensions.code === 'AUTHENTICATION_FAILED'
        );

        if (hasAuthenticationFailed) {
          clearToken();
        }

        throw new KemiApiError(errorInterface);
      } else {
        throw e;
      }
    }
  };
};
