import { useEffect } from 'react';

import { DEFAULT_LOCALE } from '@global/locale';
import { facebookService } from '@global/service';
import { setDateLocale } from '@utils/date';

// 앱구동에 필요한 글로벌한 작업들
export const useConfigAppProperties = () => {
  useEffect(() => {
    setDateLocale(DEFAULT_LOCALE);

    // Facebook SDK init
    if (!window.FB) {
      facebookService.init();
    }
  }, []);
};
