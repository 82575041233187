import { PropsWithChildren } from 'react';

import { useTheme } from '@global/hooks/useTheme';
import useUserAgent from '@shared/hooks/useUserAgent';
import { styled } from '@styles/stitches.config';

const KemiLayout = ({
  linkName,
  children,
}: PropsWithChildren<{ linkName?: string }>) => {
  const { isDesktop } = useUserAgent();
  const { theme } = useTheme(linkName || '');

  return (
    <Container className={linkName ? theme : ''}>
      <Content isDesktop={isDesktop}>{children}</Content>
    </Container>
  );
};

const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100vh',
  background: '$bgSolidColor',
});

const Content = styled('div', {
  width: '100%',
  position: 'relative',
  variants: {
    isDesktop: {
      true: {
        width: '$appWidthWhenDesktop',
      },
      false: {
        width: '100%',
      },
    },
  },
});

export default KemiLayout;
