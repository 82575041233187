import i18next from 'i18next';
import type { TOptions, StringMap } from 'i18next';

import { I18nKey } from '@i18n/types.generated';

type I18nProps = {
  /** i18next.t 메서드 key 파라미터 */
  i18nKey: I18nKey | I18nKey[];
  /** i18next.t 메서드 options 파라미터 */
  i18nOptions?: TOptions<StringMap> | string;
};

/**
 * i18next.t 메서드를 실행해 얻은 문자열을 innerHTML로 dom화 시킨다.
 *
 * 기본적으로 단순 문자열을 화면에 그릴뿐만 아니라,
 * 문자열 내에 부분적으로 a 태그를 넣거나 스타일을 주기 위해 사용한다.
 */
const I18n = (props: I18nProps) => {
  const { i18nKey, i18nOptions } = props;
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: i18next.t(i18nKey, i18nOptions),
      }}
    />
  );
};

export default I18n;
