import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { LoginModalType } from '@global/types';

type OpenModalParams = {
  type: LoginModalType;
  linkName?: string;
  redirectTo?: string;
};

type LoginAndSignupModalState = {
  isOpen: boolean;
  switchModalType: () => void;
  open: (params: OpenModalParams) => void;
  close: () => void;
} & OpenModalParams;

const useLoginAndSignupModalStore = create<LoginAndSignupModalState>()(
  devtools(
    (set) => ({
      type: LoginModalType.login,
      isOpen: false,
      linkName: undefined,
      redirectTo: '',
      switchModalType: () => {
        set(({ type }) => {
          if (type === LoginModalType.login) {
            return { type: LoginModalType.signup };
          } else {
            return { type: LoginModalType.login };
          }
        });
      },
      open: (params) => {
        set(() => ({ isOpen: true, ...params }));
      },
      close: async () => {
        set(() => ({
          type: LoginModalType.login,
          isOpen: false,
          linkName: undefined,
          redirectTo: '',
        }));
      },
    }),
    { name: 'loginAndSignupModalStore' }
  )
);

export default useLoginAndSignupModalStore;
