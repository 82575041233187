import classNames from 'classnames/bind';
import { nanoid } from 'nanoid';
import { MouseEvent, useEffect, useState } from 'react';

import Box from '../Box';
import Text from '../Text';

import styles from './ToastContainer.module.scss';

import { EVENT_TAG, KEMI_ROOT_TAG_ID } from '@global/constants';
import KemiApiError from '@global/service/Error/KemiApiError';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import CheckCircle from '@static/svg/CheckCircle';
import ExclamationMark from '@static/svg/ExclamationMark';
import variables from '@styles/variable.module.scss';
import { extract } from '@utils/string';

const cx = classNames.bind(styles);

type Toast = {
  id: string;
  content: string | JSX.Element;
};

class ToastStore {
  toasts: Toast[];
  subscribers: any[];

  constructor() {
    this.toasts = [];
    this.subscribers = [];
  }

  dispatch(toast: Toast) {
    this.toasts = this.toasts.concat([toast]);
    this.notify();
  }

  remove(id: string) {
    this.toasts = this.toasts.filter((toast) => toast.id !== id);
    this.notify();
  }

  notify() {
    this.subscribers.forEach((subscriber) => {
      subscriber(this.toasts);
    });
  }

  subscribe(callback: any) {
    this.subscribers = this.subscribers.concat([callback]);
  }

  unsubscribe(callback: any) {
    this.subscribers = this.subscribers.filter(
      (subscriber) => subscriber !== callback
    );
  }
}

const store = new ToastStore();
const ToastContainer = () => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [bottom, setBottom] = useState<number>(0);
  const { TRANSITION, TOAST_LIMIT } = getTransitionRate();
  const initialInnerHeight =
    typeof window !== 'undefined' && window.innerHeight;

  const removeToast = (id: string) => {
    store.remove(id);
  };

  const computeBottom = () => {
    if (document) {
      const bodyRef = document.body;
      const kemiRootRef = document?.querySelector(`#${KEMI_ROOT_TAG_ID}`);

      if (!bodyRef || !kemiRootRef) {
        setBottom(0);
        return;
      }

      setBottom(
        bodyRef.getBoundingClientRect()?.bottom -
          kemiRootRef.getBoundingClientRect()?.bottom
      );
    }
  };

  const handleToastItemClick = (
    e: MouseEvent<HTMLDivElement>,
    toastId: string
  ) => {
    e.currentTarget.classList.remove(cx('visible'));
    setTimeout(() => {
      removeToast(toastId);
    }, TRANSITION);
  };

  const toastCallbackRef = (el: HTMLDivElement | null) => {
    if (!el) return;

    if (el.classList.contains(cx('visible'))) return;

    setTimeout(() => {
      el.classList.add(cx('visible'));
      setTimeout(() => {
        el.classList.remove(cx('visible'));
      }, TOAST_LIMIT - TRANSITION);
    }, 0);
  };

  useEffect(() => {
    const callback = (toasts: Toast[]) => {
      setToasts(toasts);
    };

    store.subscribe(callback);

    return () => {
      store.unsubscribe(callback);
    };
  }, []);

  useEffect(() => {
    computeBottom();
    (() => {
      addThrottleEvent('resize', new CustomEvent('optimizedResize'));
    })();

    const listener = function () {
      if (initialInnerHeight !== window.innerHeight) {
        toasts.map((toast) => {
          const _toast = document.querySelector(`#toast-${toast.id}`);
          _toast?.classList.add(cx('invisible'));
          _toast?.classList.remove(cx('visible'));
          removeToast(toast.id);

          setTimeout(() => {
            removeToast(toast.id);
          }, TRANSITION);
        });
      }

      computeBottom();
    };

    window.addEventListener('optimizedResize', listener);

    return () => {
      window.removeEventListener('optimizedResize', listener);
    };
  }, [TRANSITION, initialInnerHeight, toasts]);

  return (
    <Box className={cx('container')} style={{ bottom: bottom }}>
      {toasts.map((toast) => {
        return (
          <div
            key={toast.id}
            id={`toast-${toast.id}`}
            className={cx('toast')}
            onClick={(e) => handleToastItemClick(e, toast.id)}
            ref={toastCallbackRef}
          >
            <Text color={'white'} font={'bodyRegular'} className={cx('text')}>
              {toast.content}
            </Text>
          </div>
        );
      })}
    </Box>
  );
};

const popToast = (content: string | JSX.Element) => {
  const id = nanoid();
  const { TOAST_LIMIT } = getTransitionRate();

  store.dispatch({ id, content });

  setTimeout(() => {
    store.remove(id);
  }, TOAST_LIMIT);
};

type ToastOption = {
  noOverlap?: boolean;
};

export const toast = {
  bad: (message: string | JSX.Element, option?: ToastOption) => {
    if (option && option.noOverlap) {
      const hasSameToast = store.toasts.some(
        (toast) =>
          (toast.content as JSX.Element).props.children[1].props.children ===
          message
      );

      if (hasSameToast) return;
    }

    logFirebase(UserInteractionType.TOAST, EVENT_TAG.TOAST_ERROR.TOAST_ERROR, {
      url: window.location.href,
      content: typeof message === 'string' && message,
    });

    popToast(
      <>
        <ExclamationMark className={cx('checkIcon')} color={'white'} />
        <Text font={'bodyRegular'} color={'white'}>
          {message}
        </Text>
      </>
    );
  },
  good: (message: string | JSX.Element, option?: ToastOption) => {
    if (option && option.noOverlap) {
      const hasSameToast = store.toasts.some(
        (toast) =>
          (toast.content as JSX.Element).props.children[1].props.children ===
          message
      );

      if (hasSameToast) return;
    }

    popToast(
      <>
        <CheckCircle className={cx('checkIcon')} />
        <Text font={'bodyRegular'} color={'white'}>
          {message}
        </Text>
      </>
    );
  },
};

export const toastUtils = {
  invalidWord: (error: KemiApiError) => {
    const word = extract(error.message, { prefix: '[', suffix: ']' });
    return i18n.t('k_contains_invalid_words', {
      invalid_word: word,
    });
  },
};

const getTransitionRate = () => {
  const TRANSITION = Number(variables.toastTransitionCount) * 1000;
  const TOAST_LIMIT = TRANSITION + 2700;

  return { TRANSITION, TOAST_LIMIT };
};

export default ToastContainer;

const addThrottleEvent = function (
  type: string,
  customEvent: CustomEvent,
  element?: HTMLElement | Window
) {
  element = element || window;
  let running = false;
  const func = function () {
    if (running) {
      return;
    }

    running = true;
    requestAnimationFrame(function () {
      element?.dispatchEvent(customEvent);
      running = false;
    });
  };
  element.addEventListener(type, func);
};
