import dayjs from 'dayjs';
import { throttle } from 'lodash';

import FacebookApiError from './Error/FacebookApiError';
import globalErrorHandler from './Error/globalErrorHandler';
import KemiRuntimeError from './Error/KemiRuntimeError';

import { FACEBOOK_ERROR_CODE } from '@global/constants';
import { nextApi } from '@global/network';
import {
  InstagramMediaDataFacebookAPIType,
  InstagramMediaInsigntDataFacebookAPIType,
  InstagramUserDataFacebookAPIType,
  InstagramUserInsightDataFacebookAPIType,
} from '@global/types';
import { loadSdk } from '@utils/dom';

type InstagramMediaFieldsType = {
  comments_count?: boolean;
  id?: boolean;
  ig_id?: boolean;
  like_count?: boolean;
  media_product_type?: boolean;
  media_type?: boolean;
  media_url?: boolean;
  timestamp?: boolean;
  owner?: boolean;
  thumbnail_url?: boolean;
  username?: boolean;
};

type InstagramMediaInsightMetricsType = {
  saved?: boolean;
};

type InstagramUserFieldsType = {
  biography?: boolean;
  id?: boolean;
  ig_id?: boolean;
  followers_count?: boolean;
  follows_count?: boolean;
  media_count?: boolean;
  name?: boolean;
  profile_picture_url?: boolean;
  username?: boolean;
  website?: boolean;
};

type InstagramUserInsightMetricsType = {
  online_followers?: boolean;
};

type InstagramMediaIdType = {
  id: string;
};

type FacebookLoginResponse = {
  status: 'authorization_expired' | 'connected' | 'not_authorized' | 'unknown';
  authResponse: {
    accessToken: string;
    expiresIn: number;
    signedRequest: string;
    userID: string;
    grantedScopes?: string | undefined;
    reauthorize_required_in?: number | undefined;
  };
};

const parseToQueryString = (options: { [k in string]: boolean }) =>
  Object.entries(options)
    .map(([option, value]) => (value ? option : ''))
    .filter((item) => !!item)
    .join(',');

const SAME_TOAST_DELAY = 3000;
const handleNoFacebookPageError = throttle(() => {
  globalErrorHandler(
    new FacebookApiError({
      message: FACEBOOK_ERROR_CODE.NO_FACEBOOK_PAGE,
    }),
    { toast: '페이스북 페이지가 연결되지 않았습니다.' }
  );
}, SAME_TOAST_DELAY);

const handleNoConnectedInstagramError = throttle(() => {
  globalErrorHandler(
    new FacebookApiError({
      message: FACEBOOK_ERROR_CODE.NO_CONNECTED_INSTAGRAM,
    }),
    // { toast: '인스타그램 계정이 연결되지 않았습니다.' }
    { toast: '열심히 만들고 있습니다. 조금만 기다려주세요!' }
  );
}, SAME_TOAST_DELAY);

export default class FacebookService {
  private version: string;
  private appId?: string;
  private baseUrl: string;

  scope = {
    kemi: 'public_profile,email',
    seller:
      'instagram_basic,instagram_manage_insights,pages_show_list,pages_read_engagement',
  };

  constructor() {
    this.version = 'v14.0';
    this.appId = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
    this.baseUrl = `https://graph.facebook.com/${this.version}`;
  }

  async requestFacebookAccess(scope: 'kemi' | 'seller') {
    return new Promise<FacebookLoginResponse>((resolve, reject) => {
      window.FB.getLoginStatus((checkResponse: FacebookLoginResponse) => {
        if (checkResponse.status === 'connected') {
          resolve(checkResponse);
        } else {
          window.FB.login(
            (response: FacebookLoginResponse) => {
              if (response.status === 'connected') {
                resolve(response);
              } else {
                reject();
              }
            },
            {
              scope: this.scope[scope],
            }
          );
        }
      });
    });
  }

  async init() {
    window.fbAsyncInit = () => {
      window.FB.init({
        version: this.version, // https://developers.facebook.com/docs/graph-api/guides/versioning/
        appId: this.appId,
        xfbml: true,
        cookie: true,
      });

      window.FB.AppEvents.logPageView();
    };

    await loadSdk('https://connect.facebook.net/en_US/sdk.js', 'Facebook');
  }

  facebookLogout() {
    window.FB?.logout();
  }

  async getLongTermAccessToken(token: string) {
    const res = await nextApi.getFacebookLongTermAccessToken(token);

    return res.access_token;
  }

  async getFacebookPageIds(facebookAccessToken: string) {
    const facebookPageData = await this.getFetchedFacebookJsonData(
      `${this.baseUrl}/me/accounts?access_token=${facebookAccessToken}`
    );
    const facebookPageIds = facebookPageData.data?.map((data: any) => data.id);

    if (!facebookPageIds || !facebookPageIds.length) {
      // 여기 걸리는 case
      // 1. 연결된 인스타 계정 없이 로그인
      // 2. 페이스북 페이지 미허용
      // 3. 페이스북 페이지 허용했으나 페이지 관련 권한 미승인
      handleNoFacebookPageError();
      return;
    }

    return facebookPageIds;
  }

  async getInstagramUserId(facebookAccessToken: string) {
    const facebookPageIds = await this.getFacebookPageIds(facebookAccessToken);

    if (!facebookPageIds) return;

    const instagramAccountData: {
      instagram_business_account: { id: string };
    }[] = await Promise.all(
      facebookPageIds.map((facebookPageId: string) =>
        this.getFetchedFacebookJsonData(
          `${this.baseUrl}/${facebookPageId}?fields=instagram_business_account&access_token=${facebookAccessToken}`
        )
      )
    );

    const instagramUserIds = instagramAccountData.filter((item) =>
      item.hasOwnProperty('instagram_business_account')
    );

    if (!instagramUserIds.length) {
      // 여기 걸리는 case
      // 1. 연결된 인스타 계정 없이 로그인
      // 2. 인스타그램 미허용, 페이스북 페이지 미허용
      // 3. 인스타그램 허용, 페이스북 페이지 미허용
      // 4. 인스타그램 미허용, 페이스북 페이지 허용
      // 5. 인스타그램 허용, 페이스북 페이지 허용했으나 서로 연결되어 있지 않은 것들만 허용
      // 6. 인스타그램 허용, 페이스북 페이지 허용했고 서로 연결되어 있으나 인스타 관련 권한 미승인
      handleNoConnectedInstagramError();
      return;
    }

    return instagramUserIds[0].instagram_business_account.id;
  }

  async getInstagramUserData(
    instagramUserId: string,
    facebookAccessToken: string
  ): Promise<InstagramUserDataFacebookAPIType> {
    const fields: InstagramUserFieldsType = {
      biography: true,
      id: true,
      ig_id: true,
      followers_count: true,
      follows_count: true,
      media_count: true,
      name: true,
      profile_picture_url: true,
      username: true,
      website: true,
    };

    const instagramUserData = await this.getFetchedFacebookJsonData(
      `${this.baseUrl}/${instagramUserId}?fields=${parseToQueryString(
        fields
      )}&access_token=${facebookAccessToken}`
    );

    return instagramUserData;
  }

  async getInstagramUserInsightData(
    instagramUserId: string,
    facebookAccessToken: string
  ): Promise<InstagramUserInsightDataFacebookAPIType> {
    const metric: InstagramUserInsightMetricsType = {
      online_followers: true,
    };

    const period = 'lifetime';

    const since = dayjs().subtract(1, 'day').unix().valueOf();
    const until = dayjs('2022-04-28T08:00:00+0000').unix().valueOf();

    const instagramUserData = await this.getFetchedFacebookJsonData(
      `${this.baseUrl}/${instagramUserId}/insights?metric=${parseToQueryString(
        metric
      )}&period=${period}&since=${since}&until=${until}&access_token=${facebookAccessToken}`
    );

    return instagramUserData;
  }

  async getInstagramMediaIds(
    instagramUserId: string,
    facebookAccessToken: string
  ) {
    let currentRequestUrl = `${this.baseUrl}/${instagramUserId}/media?access_token=${facebookAccessToken}`;
    let instagramMediaIds: InstagramMediaIdType[] = [];

    // mediaId는 25개씩 받아올 수 있고, next가 없을때 까지 recursive하게 호출
    while (currentRequestUrl) {
      const result = await this.getFetchedFacebookJsonData(currentRequestUrl);

      currentRequestUrl = result.paging?.next;
      instagramMediaIds =
        result.data && result.data.length > 0
          ? [...instagramMediaIds, ...result.data]
          : instagramMediaIds;
    }

    return instagramMediaIds;
  }

  async getInstagramMediaData(
    instagramUserId: string,
    facebookAccessToken: string
  ): Promise<InstagramMediaDataFacebookAPIType[]> {
    const instagramMediaIds = await this.getInstagramMediaIds(
      instagramUserId,
      facebookAccessToken
    );

    if (!instagramMediaIds) return [];

    const instagramMediaData = await this.getAllInstagramMediaFields(
      instagramMediaIds,
      facebookAccessToken
    );

    return instagramMediaData;
  }

  async getInstagramMediaInsightData(
    instagramUserId: string,
    facebookAccessToken: string
  ): Promise<InstagramMediaInsigntDataFacebookAPIType[]> {
    const instagramMediaIds = await this.getInstagramMediaIds(
      instagramUserId,
      facebookAccessToken
    );

    if (!instagramMediaIds) return [];

    const instagramMediaInsightData = await this.getAllInstagramMediaInsights(
      instagramMediaIds,
      facebookAccessToken
    );

    return instagramMediaInsightData;
  }

  public async getInstagramMediaField(
    mediaId: string,
    facebookAccessToken: string
  ) {
    const fields: InstagramMediaFieldsType = {
      comments_count: true,
      like_count: true,
      media_type: true,
      media_product_type: true,
      timestamp: true,
      id: true,
    };

    const instagramMediaFields = await this.getFetchedFacebookJsonData(
      `${this.baseUrl}/${mediaId}?fields=${parseToQueryString(
        fields
      )}&access_token=${facebookAccessToken}`
    );

    // Business 계정 전환 이전 게시물에 대해서는 400 에러 반환됨: "error_subcode": 2108006
    return instagramMediaFields;
  }

  async getAllInstagramMediaFields(
    mediaIds: InstagramMediaIdType[],
    facebookAccessToken: string
  ) {
    const instagramMediaFields = await Promise.all(
      mediaIds.map((mediaId) =>
        this.getInstagramMediaField(mediaId.id, facebookAccessToken)
      )
    );

    return instagramMediaFields;
  }

  async getInstagramMediaInsight(mediaId: string, facebookAccessToken: string) {
    const metric: InstagramMediaInsightMetricsType = {
      saved: true,
    };

    const instagramMediaInsight = await this.getFetchedFacebookJsonData(
      `${this.baseUrl}/${mediaId}/insights?metric=${parseToQueryString(
        metric
      )}&access_token=${facebookAccessToken}`
    );

    return instagramMediaInsight;
  }

  async getAllInstagramMediaInsights(
    mediaIds: InstagramMediaIdType[],
    facebookAccessToken: string
  ) {
    const instagramMediaInsight = await Promise.all(
      mediaIds.map((mediaId) =>
        this.getInstagramMediaInsight(mediaId.id, facebookAccessToken)
      )
    );

    return instagramMediaInsight;
  }

  async getFetchedFacebookJsonData(url: string) {
    try {
      const rawData = await fetch(url);
      const jsonData = await rawData.json();

      if (jsonData.error && jsonData.error.code === 190) {
        throw new KemiRuntimeError({
          message: FACEBOOK_ERROR_CODE.TOKEN_EXPIRED,
          code: 'FACEBOOK_TOKEN_EXPIRED',
        });
      }

      return jsonData;
    } catch (err: any) {
      if (err.message === FACEBOOK_ERROR_CODE.TOKEN_EXPIRED) {
        throw new KemiRuntimeError({
          message: FACEBOOK_ERROR_CODE.TOKEN_EXPIRED,
          code: 'FACEBOOK_TOKEN_EXPIRED',
        });
      }
    }
  }
}
