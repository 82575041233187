import axios from 'axios';

import {
  useLoginWithFacebookMutation,
  useRegisterWithFacebookMutation,
} from '../graphql/auth.generated';
import { createDefaultKemiOption, getOAuthCallbackUri } from '../helper';
import { FacebookAuthResponse, OAuthModule } from '../types';

import nextApi from '@global/network/NextApi';
import { generateQueryUrl } from '@utils/url';

const CLIENT_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_KEY;
// 서버에만 존재
const CLIENT_SECRET = process.env.FACEBOOK_APP_SECRET;
const REDIRECT_URI = getOAuthCallbackUri('facebook');

class FacebookOAuth implements OAuthModule {
  async authorize(state?: string) {
    const path = 'https://www.facebook.com/v14.0/dialog/oauth';
    const authorizeUrl = generateQueryUrl(path, {
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URI,
      response_type: 'code',
      scope: 'public_profile,email',
      state,
    });

    window.location.href = authorizeUrl;
  }

  async requestIssuingSnsAccessToken(code: string): Promise<string> {
    return await nextApi.issueSnsAccessToken('facebook', code);
  }

  async issueSnsAccessTokenOnServer(code: string): Promise<string> {
    const { data } = await axios.get<FacebookAuthResponse>(
      'https://graph.facebook.com/v14.0/oauth/access_token',
      {
        params: {
          client_secret: CLIENT_SECRET,
          client_id: CLIENT_ID,
          redirect_uri: REDIRECT_URI,
          code,
        },
      }
    );

    return data.access_token;
  }

  async issueKemiToken(snsAccessToken: string) {
    const kemiTokenResult = await useLoginWithFacebookMutation.fetcher({
      snsAccessToken,
    })();

    const { token, refreshToken } = kemiTokenResult.loginWithFacebookToken;

    return {
      accessToken: token,
      refreshToken,
    };
  }

  async registerKemi(snsAccessToken: string, linkName?: string) {
    await useRegisterWithFacebookMutation.fetcher({
      snsAccessToken,
      kemiOption: createDefaultKemiOption(),
      linkName,
    })();
  }
}

const facebookOAuth = new FacebookOAuth();

export default facebookOAuth;
