import { REGEX_EMAIL } from '@global/regex';

export const isEmpty = (str?: string) => !str || str === '';

export const trimAllSpace = (str: string) => {
  return str.split(' ').join('');
};

type ExtractOptions = {
  prefix: string;
  suffix: string;
};

export const extract = (target: string, options?: ExtractOptions) => {
  const prefix = options ? options.prefix : '[';
  const suffix = options ? options.suffix : ']';
  const regexp = new RegExp(`\\${prefix}(.*?)\\${suffix}`);
  const result = target.match(regexp);
  return result && result[1];
};

export const addComma = (num: number | string) => {
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ',');
};

export const removeComma = (string: string) => {
  const numberArrayWithDot = string.match(/[\d | .]/g);
  return numberArrayWithDot ? Number(numberArrayWithDot.join('')) : 0;
};

export const formatPhone = (phone: string, masking: boolean) => {
  let formatNum;

  if (phone.length == 11) {
    if (masking) {
      formatNum = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
    } else {
      formatNum = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
  } else if (phone.length == 8) {
    formatNum = phone.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (phone.indexOf('02') == 0) {
      if (masking) {
        formatNum = phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
      } else {
        formatNum = phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else {
      if (masking) {
        formatNum = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
      } else {
        formatNum = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    }
  }

  return formatNum;
};

export const isValidPhoneNumber = (phone: string) => {
  const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  return regPhone.test(phone);
};

export const isValidEmail = (email: string) => {
  return REGEX_EMAIL.test(email);
};

export const maskName = (name: string) => {
  if (name.length === 0) return '';

  return name[0] + ''.padEnd(name.length - 1, '*');
};

export const getOnlyNumber = (str: string) => {
  return str.replace(/[^0-9]+/g, '');
};
