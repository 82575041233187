import React from 'react';

import Box from '../../atoms/Box';
import Modal from '../../atoms/Modal';
import Text from '../../atoms/Text';

import styles from './ConfirmModal.module.scss';

import Button from '@global/components/atoms/Button';
import { useConfirmModalStore } from '@global/store/confirmModalStore';

const ConfirmModal = () => {
  const {
    isOpen,
    title,
    description,
    descriptionAlign = 'center',
    confirmText,
    cancelText,
    actions,
    close,
  } = useConfirmModalStore();

  const handleConfirm = async () => {
    if (actions?.confirm) {
      await actions.confirm();
    }

    close();
  };
  const handleCancel = async () => {
    if (actions?.cancel) {
      await actions.cancel();
    }

    close();
  };
  const handleClose = async () => {
    if (actions?.backgroundClick) {
      await actions.backgroundClick();
    }

    close();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      {() => {
        return (
          <Box className={styles.container}>
            <Box className={styles.innerContainer}>
              {title && (
                <Text className={styles.center} font={'bodyBold'}>
                  {title}
                </Text>
              )}
              {description && (
                <Text className={styles[descriptionAlign]} font={'bodyRegular'}>
                  {description}
                </Text>
              )}
              <Box className={styles.buttons}>
                {actions?.cancel && (
                  <Button
                    className={styles.button}
                    type={'line'}
                    onClick={handleCancel}
                  >
                    {cancelText}
                  </Button>
                )}
                <Button className={styles.button} onClick={handleConfirm}>
                  {confirmText}
                </Button>
              </Box>
            </Box>
          </Box>
        );
      }}
    </Modal>
  );
};

export default ConfirmModal;
