import classNames from 'classnames/bind';
import React, { PropsWithChildren } from 'react';

import PortalContainer from './PortalContainer';
import styles from './Wrapper.module.scss';

import Box from '@global/components/atoms/Box';
import ToastContainer from '@global/components/atoms/ToastContainer';
import ConfirmModal from '@global/components/molecules/ConfirmModal/ConfirmModal';
import LoginAndSignupModal from '@global/components/organisms/LoginAndSignupModal';
import { KEMI_ROOT_TAG_ID } from '@global/constants';
import { useFirebaseGlobalConfig } from '@pages/app/hooks/useFirebaseGlobalConfig';

const cx = classNames.bind(styles);

type WrapperProps = PropsWithChildren<{
  noLayout?: boolean;
}>;

const Wrapper = ({ noLayout, children }: WrapperProps) => {
  useFirebaseGlobalConfig();

  return (
    <Box
      id={KEMI_ROOT_TAG_ID}
      className={cx(noLayout ? 'noLayout' : 'wrapper')}
    >
      {children}
      <PortalContainer />
      <ToastContainer />
      <ConfirmModal />
      <LoginAndSignupModal />
    </Box>
  );
};

export default Wrapper;
