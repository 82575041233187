import * as Types from 'schema/types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type UpdateOrderMutationVariables = Types.Exact<{
  orderId: Types.Scalars['String'];
  orderInfo: Types.UpdateOrderInput;
}>;

export type UpdateOrderMutationResponse = {
  updateOrder?: { id: string } | null;
};

export const UpdateOrderDocument = `
    mutation updateOrder($orderId: String!, $orderInfo: UpdateOrderInput!) {
  updateOrder(orderId: $orderId, info: $orderInfo) {
    id
  }
}
    `;
export const useUpdateOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateOrderMutationResponse,
    TError,
    UpdateOrderMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateOrderMutationResponse,
    TError,
    UpdateOrderMutationVariables,
    TContext
  >(
    ['updateOrder'],
    (variables?: UpdateOrderMutationVariables) =>
      fetcher<UpdateOrderMutationResponse, UpdateOrderMutationVariables>(
        UpdateOrderDocument,
        variables
      )(),
    options
  );
useUpdateOrderMutation.getKey = () => ['updateOrder'];

useUpdateOrderMutation.fetcher = (
  variables: UpdateOrderMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<UpdateOrderMutationResponse, UpdateOrderMutationVariables>(
    UpdateOrderDocument,
    variables,
    options
  );
