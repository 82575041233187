import dayjs, { Dayjs, PluginFunc } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import 'dayjs/locale/ko';

dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(isBetween);

const isWeekend: PluginFunc = (_, c) => {
  c.prototype.isWeekend = function () {
    return this.day() === 0 || this.day() === 6;
  };
};

dayjs.extend(isWeekend);

export const setDateLocale = (locale: 'ko') => {
  dayjs.locale(locale);
};

type Mapper = (index: number) => any;

export const arrayMapper = ({
  length,
  mapper,
}: {
  length: number;
  mapper: Mapper;
}) => {
  return Array(length)
    .fill(null)
    .map((_, i) => mapper(i));
};

export const formatDate = (date: string | number, format: string) => {
  return dayjs(date).format(format);
};

export const getStartOfWeek = (date: Dayjs) => {
  return date.subtract(date.day(), 'day');
};

export const getLastOfWeek = (date: Dayjs) => {
  return date.add(6 - date.day(), 'day');
};

export const getFirstDateOfMonth = (date: Dayjs) => {
  return date.subtract(date.date() - 1, 'day');
};

export const getLastDateOfMonth = (date: Dayjs) => {
  return date.add(date.daysInMonth() - date.date(), 'day');
};

export const getWeek = (date: Dayjs, mapper?: Mapper) => {
  return arrayMapper({
    length: 7,
    mapper: mapper ? mapper : (i) => getStartOfWeek(date).add(i, 'day'),
  });
};

export const getPrevDatesOfWeek = (targetDate: Dayjs, mapper?: Mapper) => {
  return arrayMapper({
    length: targetDate.day(),
    mapper: mapper ? mapper : (i) => targetDate.subtract(i + 1, 'day'),
  });
};

export const getNextDatesOfWeek = (targetDate: Dayjs, mapper?: Mapper) => {
  return arrayMapper({
    length: 6 - targetDate.day(),
    mapper: mapper ? mapper : (i) => targetDate.add(i + 1, 'day'),
  });
};

export const convertUTCtoKST = (date: Date, format: string) => {
  return dayjs(date).add(9, 'hour').format(format);
};

export const isWithInAWeek = (date: string) => {
  const aWeekBefore = dayjs().subtract(7, 'day');
  const diff = dayjs(date).diff(aWeekBefore);

  return diff > 0 && 7 >= diff;
};

export const diffDate = (fromDate: Date, toDate: Date) => {
  const from = dayjs(fromDate);
  const to = dayjs(toDate);
  const duration = dayjs.duration(to.diff(from));
  return {
    asMonth: Math.floor(duration.asMonths()),
    asDay: Math.floor(duration.asDays()),
    asHour: Math.floor(duration.asHours()),
    asMinutes: Math.floor(duration.asMinutes()),
    asSecond: Math.floor(duration.asSeconds()),
    asMilliseconds: Math.floor(duration.asMilliseconds()),
  };
};

export const durationDaysIntoTimes = (
  fromDate: Date,
  toDate: Date,
  format: string
) => {
  const from = dayjs(fromDate);
  const to = dayjs(toDate);
  const duration = dayjs.duration(to.diff(from));

  return dayjs
    .duration({
      seconds: duration.seconds(),
      minutes: duration.minutes(),
      hours: Math.floor(duration.asHours()),
    })
    .format(format);
};

export const toUnixTimestampMicroSeconds = (date: string) => {
  return `${dayjs(date).unix().valueOf()}000000`;
};

export const formatTwoDigitTime = (time: number) => {
  return ('0' + time.toString()).slice(-2);
};

export const formatWithLocale = (date: Date, format: string) => {
  return dayjs(date).format(format);
};

export const isBusinessDay = function (date: Dayjs) {
  // TODO: 공휴일 추가
  const workingWeekdays = [1, 2, 3, 4, 5];

  return workingWeekdays.includes(date.day());
};

export const addBusinessDay = function (date: Dayjs, amount: number = 1) {
  let currentDay = date.clone();
  let restDays = amount;

  while (restDays > 0) {
    currentDay = currentDay.add(1, 'day');

    if (isBusinessDay(currentDay)) {
      restDays -= 1;
    }
  }

  return currentDay;
};
