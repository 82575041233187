import axios from 'axios';

import {
  useLoginWithGoogleMutation,
  useRegisterWithGoogleMutation,
} from '../graphql/auth.generated';
import { createDefaultKemiOption, getOAuthCallbackUri } from '../helper';
import { GoogleAuthResponse, OAuthModule } from '../types';

import nextApi from '@global/network/NextApi';
import { generateQueryUrl } from '@utils/url';

const CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_APP_KEY as string;
// 서버에만 존재
const CLIENT_SECRET = process.env.GOOGLE_APP_SECRET as string;
const REDIRECT_URI = getOAuthCallbackUri('google');

class GoogleOAuth implements OAuthModule {
  async authorize(state?: string) {
    const authorizeUrl = generateQueryUrl(
      'https://accounts.google.com/o/oauth2/v2/auth',
      {
        client_id: CLIENT_ID,
        redirect_uri: REDIRECT_URI,
        response_type: 'code',
        scope: 'https://www.googleapis.com/auth/userinfo.profile',
        state,
      }
    );
    window.location.href = authorizeUrl;
  }

  async requestIssuingSnsAccessToken(code: string): Promise<string> {
    return await nextApi.issueSnsAccessToken('google', code);
  }

  async issueSnsAccessTokenOnServer(code: string): Promise<string> {
    const { data } = await axios.post<GoogleAuthResponse>(
      'https://oauth2.googleapis.com/token',
      {},
      {
        params: {
          code,
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          redirect_uri: REDIRECT_URI,
          grant_type: 'authorization_code',
          access_type: 'offline',
        },
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      }
    );

    return data.access_token;
  }

  async issueKemiToken(snsAccessToken: string) {
    const kemiTokenResult = await useLoginWithGoogleMutation.fetcher({
      snsAccessToken,
    })();

    const { token, refreshToken } = kemiTokenResult.loginWithGoogleToken;

    return {
      accessToken: token,
      refreshToken,
    };
  }

  async registerKemi(snsAccessToken: string, linkName?: string) {
    await useRegisterWithGoogleMutation.fetcher({
      snsAccessToken,
      kemiOption: createDefaultKemiOption(),
      linkName,
    })();
  }
}

const googleOAuth = new GoogleOAuth();

export default googleOAuth;
