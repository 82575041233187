import Link from 'next/link';
import { useRouter } from 'next/router';

import Button from '@global/components/atoms/Button';
import Text from '@global/components/atoms/Text';
import { EVENT_TAG } from '@global/constants';
import useUserQuery from '@global/queries/useUserQuery';
import { logFirebase } from '@global/service/logger/EventHandler';
import translator from '@global/service/Translator';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import useGetSelectedMenu from '@pages/app/hooks/useGetSelectedMenu';
import { useGetCartItemList } from '@pages/cart/source/hooks/useGetCartItemList';
import ROUTE from '@routers';
import KemiLogo from '@static/svg/KemiLogo';
import PencilNRuler from '@static/svg/PencilNRuler';
import Seller from '@static/svg/Seller';
import Setting from '@static/svg/Setting';
import { styled } from '@styles/stitches.config';
import { getUserAgent } from '@utils/dom';

const KemiPlaygroundDesktopHeader = () => {
  const router = useRouter();
  const { data: user } = useUserQuery();
  const { data: cartItems } = useGetCartItemList();
  const cartItemCount = cartItems?.cartItems?.length || 0;
  const activeMenu = useGetSelectedMenu(router.pathname);
  const isKemi = activeMenu === 'KEMI';
  const isSellerCenter = activeMenu === 'SELLER_CENTER';
  const isCommissions = activeMenu === 'COMMISSIONS';

  return (
    <Header>
      <HeaderMenuGroup>
        <Link href={ROUTE.mykemi.home} passHref>
          <HeaderMenu
            onClick={() =>
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_MAIN_CLICK
              )
            }
          >
            <KemiLogo />
            <Text font={'subtitleBold'}>{i18n.t('k_kemi')}</Text>
          </HeaderMenu>
        </Link>
        <Link href={ROUTE.mykemi.home} passHref>
          <HeaderMenu
            active={isKemi}
            onClick={() => {
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_GENERAL_CLICK
              );
            }}
          >
            <Setting type={isKemi ? 'linedWhite' : 'linedBlack'} />
            {i18n.t('k_general')}
          </HeaderMenu>
        </Link>
        <Link href={ROUTE.sellerCenter.index} passHref>
          <HeaderMenu
            active={isSellerCenter}
            onClick={() => {
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_CLICK,
                {
                  device: getUserAgent().device.type ?? 'desktop',
                }
              );
            }}
          >
            <Seller type={isSellerCenter ? 'linedWhite' : 'linedBlack'} />
            {i18n.t('k_sale')}
          </HeaderMenu>
        </Link>
        <Link href={ROUTE.myCommissions.index} passHref>
          <HeaderMenu
            active={isCommissions}
            onClick={() => {
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_CMS_CLICK,
                {
                  device: getUserAgent().device.type ?? 'desktop',
                }
              );
            }}
          >
            <PencilNRuler type={isCommissions ? 'linedWhite' : 'linedBlack'} />
            {i18n.t('k_commission')}
          </HeaderMenu>
        </Link>
      </HeaderMenuGroup>
      <ProfileContent>
        <ProfileButtons>
          <Button
            type={'inverseNoShadow'}
            href={ROUTE.cart.index}
            onClick={() => {
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_CART_CLICK
              );
            }}
          >
            <CartButtonBox>
              <Text font={'bodyRegular'} color={'black'}>
                {i18n.t('k_shopping_basket') + ' 🛒'}
              </Text>
              {cartItemCount > 0 && <Count>{cartItemCount}</Count>}
            </CartButtonBox>
          </Button>
          <Button
            type={'inverseNoShadow'}
            href={ROUTE.orders.index}
            onClick={() => {
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_ORDERLIST_CLICK
              );
            }}
          >
            <Text font={'bodyRegular'} color={'black'}>
              {i18n.t('k_order_details') + ' 📋'}
            </Text>
          </Button>
        </ProfileButtons>
        <ProfileBox>
          <ProfileInfo>
            <ProfileImage
              src={user?.profile.profileImageUrl || ''}
              width={48}
              height={48}
            />
            <ProfileText>
              <div>{user?.profile.email}</div>
              <div>{`${translator.getSnsLoginType(user?.loginType)} ${i18n.t(
                'k_log_in'
              )}`}</div>
            </ProfileText>
          </ProfileInfo>
          <Link href={ROUTE.mykemi.personalInfo.index} passHref>
            <PersonalInfo>
              {i18n.t('k_personal_information') + ' 🔒'}
            </PersonalInfo>
          </Link>
        </ProfileBox>
      </ProfileContent>
    </Header>
  );
};

const Header = styled('div', {
  width: '100%',
  minWidth: 1040,
  maxWidth: 1600,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const HeaderMenuGroup = styled('div', {
  width: 'fit-content',
  gridColumnWithGap: 16,
  gridTemplateColumns: 'auto',
  alignItems: 'center',
});

const HeaderMenu = styled('a', {
  gridColumnWithGap: 4,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px 16px',
  cursor: 'pointer',
  variants: {
    active: {
      true: {
        background: '$black',
        color: '$white',
        borderRadius: 24,
      },
    },
  },
});

const ProfileContent = styled('div', {
  gridColumnWithGap: 16,
});

const ProfileBox = styled('div', {
  background: '$purple10',
  borderRadius: 16,
  padding: 8,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gridColumnWithGap: 16,
});

const ProfileInfo = styled('div', {
  gridColumnWithGap: 8,
  alignItems: 'center',
});

const ProfileImage = styled('img', {
  width: 48,
  height: 48,
  borderRadius: '50%',
});

const ProfileText = styled('div', {
  fontType: 'labelRegular',
  color: '$grey100',
  gridRowWithGap: 4,
});

const PersonalInfo = styled('div', {
  padding: 8,
  fontType: 'captionBold',
  color: '$black',
  cursor: 'pointer',
});

const ProfileButtons = styled('div', {
  gridColumnWithGap: 8,
  alignItems: 'center',
});

const Count = styled('div', {
  backgroundColor: '$red100',
  color: '$white',
  borderRadius: 100,
  width: 'fit-content',
  paddingX: 8,
  paddingY: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontType: 'captionRegular',
});

const CartButtonBox = styled('div', {
  gridColumnWithGap: 4,
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

export default KemiPlaygroundDesktopHeader;
