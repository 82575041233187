import create from 'zustand';

import { useMykemiForKemiStoreQuery } from './kemiStore.generated';

type KemiStore = {
  kemiId: string;
  getKemiId: (linkName: string) => Promise<string>;
};

const kemiStore = create<KemiStore>((set, get) => ({
  kemiId: '',
  getKemiId: async (linkName: string) => {
    const { kemiId } = get();

    if (!kemiId) {
      const res = await useMykemiForKemiStoreQuery.fetcher({ linkName })();
      const id = res.kemi?.id || '';

      set({ kemiId: id });
      return id;
    }

    return kemiId;
  },
}));

export default kemiStore;
