import classnames from 'classnames/bind';
import React, { PropsWithChildren } from 'react';

import Box from '../../atoms/Box';
import Modal from '../../atoms/Modal';
import Text from '../../atoms/Text';

import styles from './FloatModal.module.scss';

import Close from '@static/svg/Close';

const cx = classnames.bind(styles);

type FloatModalProps = PropsWithChildren<{
  type?: 'top' | 'center' | 'bottom' | 'desktop';
  label?: string | JSX.Element;
  isOpen: boolean;
  showLabel?: boolean;
  onClose?: (...args: any[]) => void;
}>;

const FloatModal = ({
  type = 'bottom',
  label = '',
  isOpen,
  showLabel = true,
  onClose,
  children,
}: FloatModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {({ onClose }) => {
        return (
          <Box className={cx('alignContainer', type)}>
            <Box className={cx('container')}>
              <Box className={cx('contentContainer')}>
                {showLabel && (
                  <Box className={cx('labelContainer')}>
                    {typeof label === 'string' && (
                      <Text font={'bodyBold'}>{label}</Text>
                    )}
                    {typeof label !== 'string' && label}
                    <Close
                      className={cx('closeButton')}
                      onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                        onClose(e, true)
                      }
                    />
                  </Box>
                )}
                <Box className={cx('contentsContainer')}>{children}</Box>
              </Box>
            </Box>
          </Box>
        );
      }}
    </Modal>
  );
};

export default FloatModal;
