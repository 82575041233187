import Text from '@global/components/atoms/Text';
import { EVENT_TAG } from '@global/constants';
import useUserQuery from '@global/queries/useUserQuery';
import ChannelTalk from '@global/service/ChannelTalk';
import { logFirebase } from '@global/service/logger/EventHandler';
import { useConfirmModalStore } from '@global/store/confirmModalStore';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import { ExternalLinkMenu } from '@pages/app/components/ExternalLinkMenu';
import { InternalLinkMenu } from '@pages/app/components/InternalLinkMenu';
import { Menu } from '@pages/app/components/Menu';
import { RedirectMenu } from '@pages/app/components/RedirectMenu';
import ROUTE from '@routers';
import useUserAgent from '@shared/hooks/useUserAgent';
import AddCard from '@static/svg/AddCard';
import { AddProduct } from '@static/svg/AddProduct';
import ArrowRightAngle from '@static/svg/ArrowRightAngle';
import BarGraphLine from '@static/svg/BarGraphLine';
import Customer from '@static/svg/Customer';
import Edit from '@static/svg/Edit';
import EditDesign from '@static/svg/EditDesign';
import Guide from '@static/svg/Guide';
import Home from '@static/svg/Home';
import Instagram from '@static/svg/Instagram';
import { Order } from '@static/svg/Order';
import { OrderCancel } from '@static/svg/OrderCancel';
import { OrderExchange } from '@static/svg/OrderExchange';
import { OrderReturn } from '@static/svg/OrderReturn';
import Phone from '@static/svg/Phone';
import PiggyBank from '@static/svg/PiggyBank';
import QuestionMarkHex from '@static/svg/QuestionMarkHex';
import Received from '@static/svg/Received';
import Request from '@static/svg/Request';
import { SellingAnalysis } from '@static/svg/SellingAnalysis';
import SettlementHistory from '@static/svg/SettlementHistory';
import SettlementInformation from '@static/svg/SettlementInformation';
import { Storage } from '@static/svg/Storage';
import TermOfService from '@static/svg/TermOfService';
import { styled } from '@styles/stitches.config';

// 사이드메뉴는 PC/MO에 따라 구성이 조금씩 다르지만 컴포넌트는 공통 사용합니다
// PC/MO에 따라 다른 메뉴들은 isDesktop 필드로 구분해주세요!
// 이 컴포넌트는 추후 PC/MO 혹은 대메뉴 구분에 따라 분리될 수 있어요!
const SideMenu = ({ activeMenu }: { activeMenu: string }) => {
  // 데스크탑 판단 로직이 SSR에서는 동작하지 않아 state로 선언해서 사용합니다
  const { isDesktop } = useUserAgent();
  const { data } = useUserQuery();
  const { open } = useConfirmModalStore();
  const userId = data?.userId;
  const kemiTitle = data?.title;
  const kemiLinkName = data?.linkName;
  const isSeller = data?.sellerInfo.isSeller;

  const handleClickMyCommissionProfit = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.KPG_SIDEMENU.KPG_SM_CMS_PROCEEDS_CLICK
    );
  };

  const handleKemiAnalyticsClick = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.KPG_SIDEMENU.KPG_SM_KEMI_ANALYTICS_CLICK,
      {
        menu: activeMenu,
      }
    );
  };

  const handleInstaAnalyticsClick = async () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.KPG_SIDEMENU.KPG_SM_INSTA_ANALYTICS_CLICK,
      {
        menu: activeMenu,
      }
    );
  };

  const handleContactUsClick = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.KPG_SIDEMENU.KPG_SM_CHANNELTALK_CLICK,
      {
        menu: activeMenu,
      }
    );

    if (userId && kemiTitle && kemiLinkName) {
      ChannelTalk.pushUserInfoEvent({
        userId,
        kemiTitle,
        kemiLinkName,
      });
    }
  };

  const handleGuideClick = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.KPG_SIDEMENU.KPG_SM_KEMIGUIDE_CLICK,
      { menu: activeMenu }
    );
  };

  return (
    <Container>
      {activeMenu === 'KEMI' && (
        <>
          {isDesktop && (
            <MenuGroup>
              <Title>{i18n.t('k_my_kemi')}</Title>
              <div>
                <InternalLinkMenu
                  name={i18n.t('k_kemi_home')}
                  Icon={Home}
                  targetUrls={[
                    ROUTE.mykemi.home,
                    ROUTE.mykemi.links.edit,
                    ROUTE.mykemi.textCard.edit,
                    ROUTE.mykemi.imageCard.edit,
                    ROUTE.mykemi.videoCard.edit,
                    ROUTE.mykemi.musicCard.edit,
                    ROUTE.mykemi.commissionCard.edit,
                    ROUTE.mykemi.products.edit,
                  ]}
                  onClick={() =>
                    logFirebase(
                      UserInteractionType.CLICK,
                      EVENT_TAG.KPG_SIDEMENU.KPG_SM_GENERAL_KEMIHOME_CLICK
                    )
                  }
                />
                <InternalLinkMenu
                  name={i18n.t('k_add_card')}
                  Icon={AddCard}
                  targetUrls={[
                    ROUTE.mykemi.cardType,
                    ROUTE.mykemi.links.add,
                    ROUTE.mykemi.textCard.add,
                    ROUTE.mykemi.imageCard.add,
                    ROUTE.mykemi.videoCard.add,
                    ROUTE.mykemi.musicCard.add,
                    ROUTE.mykemi.commissionCard.add,
                    ROUTE.mykemi.products.list,
                    ROUTE.mykemi.products.add,
                    ROUTE.mykemi.products.product,
                    ROUTE.mykemi.products.categories.index,
                  ]}
                  onClick={() =>
                    logFirebase(
                      UserInteractionType.CLICK,
                      EVENT_TAG.KPG_SIDEMENU.KPG_SM_GENERAL_ADDCARD_CLICK
                    )
                  }
                />
                <InternalLinkMenu
                  name={i18n.t('k_edit_profile')}
                  Icon={Edit}
                  targetUrls={[ROUTE.mykemi.settings]}
                  onClick={() =>
                    logFirebase(
                      UserInteractionType.CLICK,
                      EVENT_TAG.KPG_SIDEMENU.KPG_SM_GENERAL_EDIT_PROFILE_CLICK
                    )
                  }
                />
                <InternalLinkMenu
                  name={i18n.t('k_edit_theme')}
                  Icon={EditDesign}
                  targetUrls={[ROUTE.mykemi.editTheme]}
                  onClick={() =>
                    logFirebase(
                      UserInteractionType.CLICK,
                      EVENT_TAG.KPG_SIDEMENU.KPG_SM_GENERAL_EDIT_DESIGN_CLICK
                    )
                  }
                />
              </div>
            </MenuGroup>
          )}
          <MenuGroup>
            <Title>{i18n.t('k_analytics')}</Title>
            <div>
              <InternalLinkMenu
                name={i18n.t('k_kemi_visitor_analysis')}
                Icon={BarGraphLine}
                targetUrls={[ROUTE.mykemi.analytics.kemi.index]}
                onClick={handleKemiAnalyticsClick}
              />
              <InternalLinkMenu
                name={i18n.t('k_instagram_analytics')}
                Icon={Instagram}
                targetUrls={[ROUTE.mykemi.analytics.sns.index]}
                onClick={handleInstaAnalyticsClick}
              />
            </div>
          </MenuGroup>
        </>
      )}
      {activeMenu === 'SELLER_CENTER' && (
        <>
          <MenuGroup>
            <ExternalLinkMenu
              name={i18n.t('k_faq')}
              Icon={QuestionMarkHex}
              href={ROUTE.sellerCenter.qna}
            />
          </MenuGroup>
          <MenuGroup>
            <Title>{i18n.t('k_product_namagement')}</Title>
            <div>
              <InternalLinkMenu
                name={i18n.t('k_product_registration')}
                Icon={AddProduct}
                postfix={
                  isDesktop ? (
                    ''
                  ) : (
                    <Text font={'labelRegular'} color={'grey100'}>
                      {i18n.t(
                        'k_pc_usage_is_recommended_for_smooth_registration'
                      )}
                    </Text>
                  )
                }
                targetUrls={[
                  ROUTE.sellerCenter.products.new,
                  ROUTE.sellerCenter.products.temporary,
                ]}
                onClick={() =>
                  logFirebase(
                    UserInteractionType.CLICK,
                    EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_REGISTPRODUCT_CLICK
                  )
                }
              />
              <InternalLinkMenu
                name={i18n.t('k_product_list_title_mo')}
                Icon={Storage}
                targetUrls={[
                  ROUTE.sellerCenter.products.list,
                  ROUTE.sellerCenter.products.edit,
                ]}
                onClick={() =>
                  logFirebase(
                    UserInteractionType.CLICK,
                    EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_PRODUCTLIST_CLICK
                  )
                }
              />
            </div>
          </MenuGroup>
          <MenuGroup>
            <Title>{i18n.t('k_order_management_2')}</Title>
            {!isDesktop && (
              <div>
                <InternalLinkMenu
                  name={i18n.t('k_check_order')}
                  Icon={Order}
                  targetUrls={[ROUTE.sellerCenter.orders]}
                />
              </div>
            )}
            {isDesktop && (
              <div>
                <InternalLinkMenu
                  name={i18n.t('k_consolidated_order')}
                  Icon={Order}
                  targetUrls={[ROUTE.sellerCenter.orders]}
                  onClick={() =>
                    logFirebase(
                      UserInteractionType.CLICK,
                      EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_ENTIREORDER_CLICK
                    )
                  }
                />
                <InternalLinkMenu
                  name={i18n.t('k_exchange_management')}
                  Icon={OrderExchange}
                  targetUrls={[ROUTE.sellerCenter.exchanges]}
                  onClick={() =>
                    logFirebase(
                      UserInteractionType.CLICK,
                      EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_EXCHANGEADM_CLICK
                    )
                  }
                />
                <InternalLinkMenu
                  name={i18n.t('k_return_management')}
                  Icon={OrderReturn}
                  targetUrls={[ROUTE.sellerCenter.returns]}
                  onClick={() =>
                    logFirebase(
                      UserInteractionType.CLICK,
                      EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_REFUNDADM_CLICK
                    )
                  }
                />
                <InternalLinkMenu
                  name={i18n.t('k_cancellation_management')}
                  Icon={OrderCancel}
                  targetUrls={[ROUTE.sellerCenter.cancels]}
                  onClick={() =>
                    logFirebase(
                      UserInteractionType.CLICK,
                      EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_CANCELADM_CLICK
                    )
                  }
                />
              </div>
            )}
          </MenuGroup>
          <MenuGroup>
            <Title>{i18n.t('k_settlement_management')}</Title>
            <div>
              {/* 정산정보에 문제가 있어 임시로 숨김처리 */}
              {/*<InternalLinkMenu*/}
              {/*  name={i18n.t('k_settlement_summary')}*/}
              {/*  Icon={PiggyBank}*/}
              {/*  targetUrls={[ROUTE.sellerCenter.settlement.summary]}*/}
              {/*/>*/}
              <InternalLinkMenu
                name={i18n.t('k_settlement_details')}
                Icon={SettlementHistory}
                targetUrls={[ROUTE.sellerCenter.settlement.history]}
              />
              <InternalLinkMenu
                name={i18n.t('k_settlement_information')}
                Icon={SettlementInformation}
                targetUrls={[ROUTE.sellerCenter.settlement.info]}
              />
            </div>
          </MenuGroup>
          <MenuGroup>
            <Title>{i18n.t('k_customer_care')}</Title>
            <div>
              <Menu
                name={i18n.t('k_customer_analysis')}
                Icon={Customer}
                postfix={<ArrowRightAngle />}
                onClick={() => {
                  logFirebase(
                    UserInteractionType.CLICK,
                    EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_CONSUMERADM_CLICK
                  );
                  open({
                    title: i18n.t('k_this_feature_is_currently_being_prepared'),
                    description: i18n.t(
                      'k_kemi_will_support_the_function_to_analyze_the_purchasing_customer_in_detail'
                    ),
                  });
                }}
              />
            </div>
          </MenuGroup>
          <MenuGroup>
            <Title>{i18n.t('k_analytics')}</Title>
            <div>
              {isSeller && (
                <InternalLinkMenu
                  name={i18n.t('k_sales_analytics')}
                  Icon={SellingAnalysis}
                  targetUrls={[ROUTE.sellerCenter.analytics.sales.index]}
                  onClick={() =>
                    logFirebase(
                      UserInteractionType.CLICK,
                      EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_SALES_ANALYTICS_CLICK
                    )
                  }
                />
              )}
              <RedirectMenu
                name={i18n.t('k_kemi_visitor_analysis')}
                Icon={BarGraphLine}
                targetUrls={[ROUTE.mykemi.analytics.kemi.index]}
                onClick={handleKemiAnalyticsClick}
              />
              <RedirectMenu
                name={i18n.t('k_instagram_analytics')}
                Icon={Instagram}
                targetUrls={[ROUTE.mykemi.analytics.sns.index]}
                onClick={handleInstaAnalyticsClick}
              />
            </div>
          </MenuGroup>
        </>
      )}
      {activeMenu === 'COMMISSIONS' && (
        <>
          <MenuGroup>
            <Title>{i18n.t('k_commission_management')}</Title>
            <div>
              <InternalLinkMenu
                name={i18n.t('k_requesting_commission')}
                Icon={Request}
                targetUrls={[ROUTE.myCommissions.orders.request.index]}
                onClick={() =>
                  logFirebase(
                    UserInteractionType.CLICK,
                    EVENT_TAG.KPG_SIDEMENU.KPG_SM_CMS_REQUIRING_CLICK
                  )
                }
              />
              <InternalLinkMenu
                name={i18n.t('k_requested_commission')}
                Icon={Received}
                targetUrls={[ROUTE.myCommissions.orders.receive.index]}
                onClick={() =>
                  logFirebase(
                    UserInteractionType.CLICK,
                    EVENT_TAG.KPG_SIDEMENU.KPG_SM_CMS_REQUIRED_CLICK
                  )
                }
              />
              <InternalLinkMenu
                name={i18n.t('k_commission_profit')}
                Icon={PiggyBank}
                targetUrls={[ROUTE.myCommissions.settlement.index]}
                onClick={handleClickMyCommissionProfit}
              />
            </div>
          </MenuGroup>
          <MenuGroup>
            <Title>{i18n.t('k_analytics')}</Title>
            <div>
              <RedirectMenu
                name={i18n.t('k_kemi_visitor_analysis')}
                Icon={BarGraphLine}
                targetUrls={[ROUTE.mykemi.analytics.kemi.index]}
                onClick={handleKemiAnalyticsClick}
              />
              <RedirectMenu
                name={i18n.t('k_instagram_analytics')}
                Icon={Instagram}
                targetUrls={[ROUTE.mykemi.analytics.sns.index]}
                onClick={handleInstaAnalyticsClick}
              />
            </div>
          </MenuGroup>
        </>
      )}
      <MenuGroup>
        <Title>{i18n.t('k_etc')}</Title>
        <div>
          <ExternalLinkMenu
            name={i18n.t('k_kemi_guide')}
            Icon={Guide}
            href={'https://kemi.oopy.io/'}
            onClick={handleGuideClick}
          />
          <ExternalLinkMenu
            name={i18n.t('k_contact_us')}
            Icon={Phone}
            href={'https://kemi.channel.io'}
            onClick={handleContactUsClick}
          />
          <ExternalLinkMenu
            name={i18n.t('k_terms_of_use')}
            Icon={TermOfService}
            href={ROUTE.terms}
          />
        </div>
      </MenuGroup>
    </Container>
  );
};

const Container = styled('div', {
  height: 'fit-content',
  gridRowWithGap: 24,
  gridTemplateRows: 'auto',
  paddingBottom: 90,
});

const MenuGroup = styled('div', {
  gridRowWithGap: 8,
  height: 'fit-content',
});

const Title = styled('div', {
  fontType: 'bodyBold',
});

export default SideMenu;
