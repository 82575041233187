import { SVGProps } from 'react';

import colors from '@styles/color.module.scss';

type InstagramProps = {
  color?: string;
  size?: 'small' | 'big';
} & SVGProps<SVGSVGElement>;

const Instagram = ({
  color = colors.black,
  size,
  style,
  ...rest
}: InstagramProps) => {
  const _style =
    size === 'small' ? { transform: 'scale(0.5)', ...style } : style;

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      style={_style}
      {...rest}
    >
      <circle
        cx={'12'}
        cy={'12'}
        r={'4'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <rect
        x={'4'}
        y={'4'}
        width={'16'}
        height={'16'}
        rx={'5'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <circle cx={'16.7'} cy={'7.29999'} r={'1'} fill={color} />
    </svg>
  );
};

export default Instagram;
