/* eslint-disable no-console */
import router from 'next/router';

import { errorLogger } from '../logger';

import KemiApiError from './KemiApiError';

import { toast } from '@global/components/atoms/ToastContainer';
import i18n from '@i18n/index';
import ROUTE from '@routers';

type GlobalErrorHandlerOptions = Partial<{
  redirect: boolean | string;
  toast: boolean | string;
  log: boolean;
}>;

const defaultOptions: GlobalErrorHandlerOptions = {
  redirect: false,
  toast: false,
  log: true,
};

const globalErrorHandler = (
  error: any,
  options?: GlobalErrorHandlerOptions
) => {
  const _options = Object.assign({ ...defaultOptions }, options);
  const isBrowser = typeof window !== 'undefined';

  if (_options.redirect) {
    if (!isBrowser) return;

    const defaultRoute = ROUTE.index;
    const route =
      typeof _options.redirect === 'string' ? _options.redirect : defaultRoute;
    router.push(route);
  }

  if (_options.toast) {
    if (!isBrowser) return;

    const message =
      typeof _options.toast === 'string'
        ? _options.toast
        : i18n.t('k_an_unknown_error_occurred');
    toast.bad(message);
  }

  if (_options.log) {
    errorLogger.log({
      data: error,
    });
  }
};

export const checkKemiApiErrorCode = (
  error: any,
  code: KemiApiError['code']
): error is KemiApiError => {
  return error instanceof KemiApiError && error.code === code;
};

export default globalErrorHandler;
