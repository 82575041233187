const PiggyBank = ({ ...rest }) => {
  const color = rest.type === 'linedWhite' ? 'white' : '#303030';
  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={
          'M7 7V10C5.8 10.4 4.5 11.5 4 13H3.2198C2.58876 13 2.11547 13.5773 2.23922 14.1961L2.83922 17.1961C2.93271 17.6635 3.34312 18 3.8198 18H5C5.5 19 7.2 21 10 21H16C20 21 22 18 22 15C22 12 20 9 16 9H10.5C9.7 7.4 7.83333 7 7 7Z'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M7 14V15'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M11 11H16'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M7 22L7.5 20.5'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M18.5 20.5L19 22'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <circle
        cx={'13.5'}
        cy={'5.5'}
        r={'3.5'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default PiggyBank;
