import dayjs from 'dayjs';
import { cloneDeep, round, sum } from 'lodash';

import i18n from '@i18n/index';
import { CurrentDateState } from '@pages/mykemi/analytics/source/components/GraphCard/GraphType/BarGraph/WeeklyTimeBarGraph';
import { TimeDistributionDataSet } from '@pages/mykemi/analytics/source/components/TimeDistributionGraph';
import { formatTwoDigitTime } from '@utils/date';

export type KemiTabKeys = 'summary' | 'details' | 'visitors';

export const kemiTabNames: { key: KemiTabKeys; label: string }[] = [
  { key: 'summary', label: i18n.t('k_summary') },
  { key: 'details', label: i18n.t('k_detail_info') },
  { key: 'visitors', label: i18n.t('k_visitor_analytics') },
];

export const getKemiTabKeyByIndex = (index: number): KemiTabKeys => {
  return kemiTabNames[index]?.key || kemiTabNames[0].key;
};

export type SnsTabKeys = 'summary' | 'details';

export const snsTabNames: { key: SnsTabKeys; label: string }[] = [
  { key: 'summary', label: i18n.t('k_summary') },
  { key: 'details', label: i18n.t('k_detail_info') },
];

export const getSnsTabKeyByIndex = (index: number): SnsTabKeys => {
  return snsTabNames[index]?.key || snsTabNames[0].key;
};

export const getAnchorId = (id: string) => {
  return `anchor-${id}`;
};

export const getAnchorIdHash = (hash: string) => {
  return `#${getAnchorId(hash.replace('#', ''))}`;
};

export const getHashString = (url: string) => {
  return url.split('#')[1] || '';
};

export const getWeekDateLabels = (startDate: string) => {
  return new Array(7).fill(null).map((_, i) => {
    if (i % 2 === 0) {
      return dayjs(startDate).add(i, 'day').format('M.D');
    }

    return null;
  });
};

export const EVENT_DATE_FORMAT = 'YYYY-MM-DD';

export const getDailyValueInWeek = (
  startDate: string,
  data: { eventDate: string; value: number }[]
) => {
  const cloned = cloneDeep(data);
  return new Array(7).fill(null).reduce<{ values: number[]; total: number }>(
    (acc, _, i) => {
      const target = cloned.find(
        (stat) =>
          stat.eventDate ===
          dayjs(startDate).add(i, 'day').format(EVENT_DATE_FORMAT)
      );

      if (target) {
        const value = target?.value || 0;

        acc.values.push(value);
        acc.total += value;

        return acc;
      }

      acc.values.push(0);

      return acc;
    },
    { values: [], total: 0 }
  );
};

export const getAnalyticsDivisions = (
  numerator: number,
  denominator: number
) => {
  const division = numerator / denominator;

  if (division === Infinity) {
    return numerator;
  }

  return division || 0;
};

export const getAnalyticsRate = (
  numerator: number,
  denominator: number,
  precision: number = 1
) => {
  return round(getAnalyticsDivisions(numerator, denominator) * 100, precision);
};

export const getMostTime = (timeDistributions: number[]) => {
  return timeDistributions.reduce<{
    total: number;
    mostTime: number;
  }>(
    (acc, visitors, hour, self) => {
      acc.total += visitors;
      acc.mostTime = visitors > self[acc.mostTime] ? hour : acc.mostTime;
      return acc;
    },
    { total: 0, mostTime: 0 }
  );
};

export const getSummaryText = (
  mostTime: number,
  total: number,
  valueSuffix: string,
  mostValue: number,
  showMostValue?: boolean
) => {
  return `🎖️ ${formatTwoDigitTime(mostTime)}:00 - ${formatTwoDigitTime(
    mostTime + 1
  )}:00 ${getAnalyticsRate(mostValue, total, 0)}%${
    showMostValue ? `(${mostValue}${valueSuffix})` : ''
  }`;
};

export const getTimeDistributionSummaryText = (
  timeDistributions: TimeDistributionDataSet,
  current: CurrentDateState,
  activeIndex: number,
  valueSuffix: string
) => {
  const currentTimeDistributions = timeDistributions[current.index].data;
  const total = sum(currentTimeDistributions);
  const value = currentTimeDistributions[activeIndex];

  return getSummaryText(activeIndex, total, valueSuffix, value, true);
};

export const getTopValueIndex = (arr: number[]) => {
  return arr.reduce((topIndex, value, index) => {
    return value > arr[topIndex] ? index : topIndex;
  }, 0);
};

export const BAR_GRAPH_INITIAL_INDEX = 6;

export const getCurrentPositionMenu = (
  menu: { id: string; text: string }[],
  offset: number
) => {
  for (let i = menu.length - 1; i >= 0; i--) {
    const id = menu[i].id;
    const el = document.querySelector(getAnchorIdHash(id));
    const top = el?.getClientRects()[0].top || 0;

    if (top < offset) {
      return id;
    }
  }

  return menu[0]?.id;
};

export const ONE_HOUR = 60 * 60 * 1000;
