import * as Types from 'schema/types';

import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type GetCartItemListQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetCartItemListQueryResponse = {
  cartItems?: Array<{
    id: string;
    checked?: boolean | null;
    details?: string | null;
    productCardId?: string | null;
    productId?: string | null;
    sellerUserId?: string | null;
    sellerLinkName?: string | null;
    createdAt: string;
    updatedAt: string;
    productCard?: {
      id: string;
      userId?: string | null;
      title: string;
      price: number;
      suggestedRetailPrice?: number | null;
      productId?: string | null;
      productDetail?: {
        id: string;
        providerId?: string | null;
        title?: string | null;
        sku?: string | null;
        description?: string | null;
        imageUrls?: Array<string> | null;
        suggestedRetailPrice?: number | null;
        productState?: Types.EProductState | null;
        kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
        kemiStockState?: Types.EProductKemiStockState | null;
        maxSaleDaysPerSeller?: number | null;
        kemiSalesStartAt?: string | null;
        kemiSalesEndAt?: string | null;
        maxBuyPerOrder?: number | null;
        supplyPrice?: number | null;
        isRetailPriceChangeable?: boolean | null;
        discount?: {
          type: Types.EDiscountType;
          price?: number | null;
          rate?: number | null;
        } | null;
        shipping?: {
          shippingChargeType?: Types.EShippingChargeType | null;
          minPriceForFreeShipping?: number | null;
          shippingCharge?: number | null;
          extraChargeForIslandsAndMountains?: number | null;
          shippingLocationType?: Types.EShippingLocationType | null;
        } | null;
        stock?: {
          isInfiniteStock?: boolean | null;
          accumulatedInventory?: number | null;
          availableStock?: number | null;
        } | null;
        options?: Array<{
          sku?: string | null;
          title?: string | null;
          image?: string | null;
          salesState?: Types.EOptionSalesState | null;
          supplyPrice?: number | null;
          suggestedRetailPrice?: number | null;
          requiredNumberOfExtraOption?: number | null;
          isExtraOptionExist?: boolean | null;
          extraOptionDescription?: string | null;
          stock?: {
            isInfiniteStock?: boolean | null;
            accumulatedInventory?: number | null;
            availableStock?: number | null;
          } | null;
          discount?: {
            type: Types.EDiscountType;
            rate?: number | null;
            price?: number | null;
          } | null;
          extraOptions?: Array<{
            title?: string | null;
            sku?: string | null;
            stock?: {
              isInfiniteStock?: boolean | null;
              accumulatedInventory?: number | null;
              availableStock?: number | null;
            } | null;
          }> | null;
        }> | null;
      } | null;
    } | null;
    product?: {
      id: string;
      providerId?: string | null;
      title?: string | null;
      sku?: string | null;
      description?: string | null;
      imageUrls?: Array<string> | null;
      suggestedRetailPrice?: number | null;
      productState?: Types.EProductState | null;
      kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
      kemiStockState?: Types.EProductKemiStockState | null;
      maxSaleDaysPerSeller?: number | null;
      kemiSalesStartAt?: string | null;
      kemiSalesEndAt?: string | null;
      maxBuyPerOrder?: number | null;
      supplyPrice?: number | null;
      isRetailPriceChangeable?: boolean | null;
      discount?: {
        type: Types.EDiscountType;
        price?: number | null;
        rate?: number | null;
      } | null;
      shipping?: {
        shippingChargeType?: Types.EShippingChargeType | null;
        minPriceForFreeShipping?: number | null;
        shippingCharge?: number | null;
        extraChargeForIslandsAndMountains?: number | null;
        shippingLocationType?: Types.EShippingLocationType | null;
      } | null;
      stock?: {
        isInfiniteStock?: boolean | null;
        accumulatedInventory?: number | null;
        availableStock?: number | null;
      } | null;
      options?: Array<{
        sku?: string | null;
        title?: string | null;
        image?: string | null;
        salesState?: Types.EOptionSalesState | null;
        supplyPrice?: number | null;
        suggestedRetailPrice?: number | null;
        requiredNumberOfExtraOption?: number | null;
        isExtraOptionExist?: boolean | null;
        extraOptionDescription?: string | null;
        stock?: {
          isInfiniteStock?: boolean | null;
          accumulatedInventory?: number | null;
          availableStock?: number | null;
        } | null;
        discount?: {
          type: Types.EDiscountType;
          rate?: number | null;
          price?: number | null;
        } | null;
        extraOptions?: Array<{
          title?: string | null;
          sku?: string | null;
          stock?: {
            isInfiniteStock?: boolean | null;
            accumulatedInventory?: number | null;
            availableStock?: number | null;
          } | null;
        }> | null;
      }> | null;
    } | null;
    sellerUser?: {
      id: string;
      linkName: string;
      kemi: {
        id: string;
        linkName: string;
        profileImageUrl?: string | null;
        title: string;
      };
    } | null;
  }> | null;
};

export type GetCartItemListCountQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetCartItemListCountQueryResponse = {
  cartItemCount?: { totalCount: number } | null;
};

export type GetCartItemInfoQueryVariables = Types.Exact<{
  cardIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type GetCartItemInfoQueryResponse = {
  cards: Array<
    | {
        id: string;
        title: string;
        price: number;
        suggestedRetailPrice?: number | null;
        productId?: string | null;
        user?: {
          id: string;
          kemi: {
            linkName: string;
            profileImageUrl?: string | null;
            title: string;
          };
        } | null;
        productDetail?: {
          id: string;
          providerId?: string | null;
          title?: string | null;
          sku?: string | null;
          description?: string | null;
          imageUrls?: Array<string> | null;
          suggestedRetailPrice?: number | null;
          productState?: Types.EProductState | null;
          kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
          kemiStockState?: Types.EProductKemiStockState | null;
          maxSaleDaysPerSeller?: number | null;
          kemiSalesStartAt?: string | null;
          kemiSalesEndAt?: string | null;
          maxBuyPerOrder?: number | null;
          supplyPrice?: number | null;
          isRetailPriceChangeable?: boolean | null;
          discount?: {
            type: Types.EDiscountType;
            price?: number | null;
            rate?: number | null;
          } | null;
          shipping?: {
            shippingChargeType?: Types.EShippingChargeType | null;
            minPriceForFreeShipping?: number | null;
            shippingCharge?: number | null;
            extraChargeForIslandsAndMountains?: number | null;
            shippingLocationType?: Types.EShippingLocationType | null;
          } | null;
          stock?: {
            isInfiniteStock?: boolean | null;
            accumulatedInventory?: number | null;
            availableStock?: number | null;
          } | null;
          options?: Array<{
            sku?: string | null;
            title?: string | null;
            image?: string | null;
            salesState?: Types.EOptionSalesState | null;
            supplyPrice?: number | null;
            suggestedRetailPrice?: number | null;
            requiredNumberOfExtraOption?: number | null;
            isExtraOptionExist?: boolean | null;
            extraOptionDescription?: string | null;
            stock?: {
              isInfiniteStock?: boolean | null;
              accumulatedInventory?: number | null;
              availableStock?: number | null;
            } | null;
            discount?: {
              type: Types.EDiscountType;
              rate?: number | null;
              price?: number | null;
            } | null;
            extraOptions?: Array<{
              title?: string | null;
              sku?: string | null;
              stock?: {
                isInfiniteStock?: boolean | null;
                accumulatedInventory?: number | null;
                availableStock?: number | null;
              } | null;
            }> | null;
          }> | null;
        } | null;
      }
    | {}
    | null
  >;
};

export type AddCartItemListMutationVariables = Types.Exact<{
  cartInput: Types.AddToCartInput;
}>;

export type AddCartItemListMutationResponse = {
  addToCart?: {
    totalCount: number;
    items?: Array<{
      id: string;
      checked?: boolean | null;
      details?: string | null;
      productCardId?: string | null;
      productId?: string | null;
      sellerUserId?: string | null;
      sellerLinkName?: string | null;
      createdAt: string;
      updatedAt: string;
      productCard?: {
        id: string;
        userId?: string | null;
        title: string;
        price: number;
        suggestedRetailPrice?: number | null;
        productId?: string | null;
        productDetail?: {
          id: string;
          providerId?: string | null;
          title?: string | null;
          sku?: string | null;
          description?: string | null;
          imageUrls?: Array<string> | null;
          suggestedRetailPrice?: number | null;
          productState?: Types.EProductState | null;
          kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
          kemiStockState?: Types.EProductKemiStockState | null;
          maxSaleDaysPerSeller?: number | null;
          kemiSalesStartAt?: string | null;
          kemiSalesEndAt?: string | null;
          maxBuyPerOrder?: number | null;
          supplyPrice?: number | null;
          isRetailPriceChangeable?: boolean | null;
          discount?: {
            type: Types.EDiscountType;
            price?: number | null;
            rate?: number | null;
          } | null;
          shipping?: {
            shippingChargeType?: Types.EShippingChargeType | null;
            minPriceForFreeShipping?: number | null;
            shippingCharge?: number | null;
            extraChargeForIslandsAndMountains?: number | null;
            shippingLocationType?: Types.EShippingLocationType | null;
          } | null;
          stock?: {
            isInfiniteStock?: boolean | null;
            accumulatedInventory?: number | null;
            availableStock?: number | null;
          } | null;
          options?: Array<{
            sku?: string | null;
            title?: string | null;
            image?: string | null;
            salesState?: Types.EOptionSalesState | null;
            supplyPrice?: number | null;
            suggestedRetailPrice?: number | null;
            requiredNumberOfExtraOption?: number | null;
            isExtraOptionExist?: boolean | null;
            extraOptionDescription?: string | null;
            stock?: {
              isInfiniteStock?: boolean | null;
              accumulatedInventory?: number | null;
              availableStock?: number | null;
            } | null;
            discount?: {
              type: Types.EDiscountType;
              rate?: number | null;
              price?: number | null;
            } | null;
            extraOptions?: Array<{
              title?: string | null;
              sku?: string | null;
              stock?: {
                isInfiniteStock?: boolean | null;
                accumulatedInventory?: number | null;
                availableStock?: number | null;
              } | null;
            }> | null;
          }> | null;
        } | null;
      } | null;
      product?: {
        id: string;
        providerId?: string | null;
        title?: string | null;
        sku?: string | null;
        description?: string | null;
        imageUrls?: Array<string> | null;
        suggestedRetailPrice?: number | null;
        productState?: Types.EProductState | null;
        kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
        kemiStockState?: Types.EProductKemiStockState | null;
        maxSaleDaysPerSeller?: number | null;
        kemiSalesStartAt?: string | null;
        kemiSalesEndAt?: string | null;
        maxBuyPerOrder?: number | null;
        supplyPrice?: number | null;
        isRetailPriceChangeable?: boolean | null;
        discount?: {
          type: Types.EDiscountType;
          price?: number | null;
          rate?: number | null;
        } | null;
        shipping?: {
          shippingChargeType?: Types.EShippingChargeType | null;
          minPriceForFreeShipping?: number | null;
          shippingCharge?: number | null;
          extraChargeForIslandsAndMountains?: number | null;
          shippingLocationType?: Types.EShippingLocationType | null;
        } | null;
        stock?: {
          isInfiniteStock?: boolean | null;
          accumulatedInventory?: number | null;
          availableStock?: number | null;
        } | null;
        options?: Array<{
          sku?: string | null;
          title?: string | null;
          image?: string | null;
          salesState?: Types.EOptionSalesState | null;
          supplyPrice?: number | null;
          suggestedRetailPrice?: number | null;
          requiredNumberOfExtraOption?: number | null;
          isExtraOptionExist?: boolean | null;
          extraOptionDescription?: string | null;
          stock?: {
            isInfiniteStock?: boolean | null;
            accumulatedInventory?: number | null;
            availableStock?: number | null;
          } | null;
          discount?: {
            type: Types.EDiscountType;
            rate?: number | null;
            price?: number | null;
          } | null;
          extraOptions?: Array<{
            title?: string | null;
            sku?: string | null;
            stock?: {
              isInfiniteStock?: boolean | null;
              accumulatedInventory?: number | null;
              availableStock?: number | null;
            } | null;
          }> | null;
        }> | null;
      } | null;
      sellerUser?: {
        id: string;
        linkName: string;
        kemi: {
          id: string;
          linkName: string;
          profileImageUrl?: string | null;
          title: string;
        };
      } | null;
    }> | null;
  } | null;
};

export type UpdateCartItemMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  cartItem: Types.CartItemInput;
}>;

export type UpdateCartItemMutationResponse = {
  updateCartItem?: {
    id: string;
    updatedCartItem?: {
      id: string;
      checked?: boolean | null;
      details?: string | null;
      productCardId?: string | null;
      productId?: string | null;
      sellerUserId?: string | null;
      sellerLinkName?: string | null;
      createdAt: string;
      updatedAt: string;
      productCard?: {
        id: string;
        userId?: string | null;
        title: string;
        price: number;
        suggestedRetailPrice?: number | null;
        productId?: string | null;
        productDetail?: {
          id: string;
          providerId?: string | null;
          title?: string | null;
          sku?: string | null;
          description?: string | null;
          imageUrls?: Array<string> | null;
          suggestedRetailPrice?: number | null;
          productState?: Types.EProductState | null;
          kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
          kemiStockState?: Types.EProductKemiStockState | null;
          maxSaleDaysPerSeller?: number | null;
          kemiSalesStartAt?: string | null;
          kemiSalesEndAt?: string | null;
          maxBuyPerOrder?: number | null;
          supplyPrice?: number | null;
          isRetailPriceChangeable?: boolean | null;
          discount?: {
            type: Types.EDiscountType;
            price?: number | null;
            rate?: number | null;
          } | null;
          shipping?: {
            shippingChargeType?: Types.EShippingChargeType | null;
            minPriceForFreeShipping?: number | null;
            shippingCharge?: number | null;
            extraChargeForIslandsAndMountains?: number | null;
            shippingLocationType?: Types.EShippingLocationType | null;
          } | null;
          stock?: {
            isInfiniteStock?: boolean | null;
            accumulatedInventory?: number | null;
            availableStock?: number | null;
          } | null;
          options?: Array<{
            sku?: string | null;
            title?: string | null;
            image?: string | null;
            salesState?: Types.EOptionSalesState | null;
            supplyPrice?: number | null;
            suggestedRetailPrice?: number | null;
            requiredNumberOfExtraOption?: number | null;
            isExtraOptionExist?: boolean | null;
            extraOptionDescription?: string | null;
            stock?: {
              isInfiniteStock?: boolean | null;
              accumulatedInventory?: number | null;
              availableStock?: number | null;
            } | null;
            discount?: {
              type: Types.EDiscountType;
              rate?: number | null;
              price?: number | null;
            } | null;
            extraOptions?: Array<{
              title?: string | null;
              sku?: string | null;
              stock?: {
                isInfiniteStock?: boolean | null;
                accumulatedInventory?: number | null;
                availableStock?: number | null;
              } | null;
            }> | null;
          }> | null;
        } | null;
      } | null;
      product?: {
        id: string;
        providerId?: string | null;
        title?: string | null;
        sku?: string | null;
        description?: string | null;
        imageUrls?: Array<string> | null;
        suggestedRetailPrice?: number | null;
        productState?: Types.EProductState | null;
        kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
        kemiStockState?: Types.EProductKemiStockState | null;
        maxSaleDaysPerSeller?: number | null;
        kemiSalesStartAt?: string | null;
        kemiSalesEndAt?: string | null;
        maxBuyPerOrder?: number | null;
        supplyPrice?: number | null;
        isRetailPriceChangeable?: boolean | null;
        discount?: {
          type: Types.EDiscountType;
          price?: number | null;
          rate?: number | null;
        } | null;
        shipping?: {
          shippingChargeType?: Types.EShippingChargeType | null;
          minPriceForFreeShipping?: number | null;
          shippingCharge?: number | null;
          extraChargeForIslandsAndMountains?: number | null;
          shippingLocationType?: Types.EShippingLocationType | null;
        } | null;
        stock?: {
          isInfiniteStock?: boolean | null;
          accumulatedInventory?: number | null;
          availableStock?: number | null;
        } | null;
        options?: Array<{
          sku?: string | null;
          title?: string | null;
          image?: string | null;
          salesState?: Types.EOptionSalesState | null;
          supplyPrice?: number | null;
          suggestedRetailPrice?: number | null;
          requiredNumberOfExtraOption?: number | null;
          isExtraOptionExist?: boolean | null;
          extraOptionDescription?: string | null;
          stock?: {
            isInfiniteStock?: boolean | null;
            accumulatedInventory?: number | null;
            availableStock?: number | null;
          } | null;
          discount?: {
            type: Types.EDiscountType;
            rate?: number | null;
            price?: number | null;
          } | null;
          extraOptions?: Array<{
            title?: string | null;
            sku?: string | null;
            stock?: {
              isInfiniteStock?: boolean | null;
              accumulatedInventory?: number | null;
              availableStock?: number | null;
            } | null;
          }> | null;
        }> | null;
      } | null;
      sellerUser?: {
        id: string;
        linkName: string;
        kemi: {
          id: string;
          linkName: string;
          profileImageUrl?: string | null;
          title: string;
        };
      } | null;
    } | null;
  } | null;
};

export type UpdateCartItemsCheckedMutationVariables = Types.Exact<{
  cartItemsChecked: Types.UpdateCartItemsChecked;
}>;

export type UpdateCartItemsCheckedMutationResponse = {
  updateCartItemsChecked?: { itemIDs: Array<string> } | null;
};

export type DeleteCartItemListMutationVariables = Types.Exact<{
  cartInput: Types.RemoveFromCartInput;
}>;

export type DeleteCartItemListMutationResponse = {
  removeFromCart?: { ids?: Array<string> | null } | null;
};

export type CartItemPartsFragment = {
  id: string;
  checked?: boolean | null;
  details?: string | null;
  productCardId?: string | null;
  productId?: string | null;
  sellerUserId?: string | null;
  sellerLinkName?: string | null;
  createdAt: string;
  updatedAt: string;
  productCard?: {
    id: string;
    userId?: string | null;
    title: string;
    price: number;
    suggestedRetailPrice?: number | null;
    productId?: string | null;
    productDetail?: {
      id: string;
      providerId?: string | null;
      title?: string | null;
      sku?: string | null;
      description?: string | null;
      imageUrls?: Array<string> | null;
      suggestedRetailPrice?: number | null;
      productState?: Types.EProductState | null;
      kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
      kemiStockState?: Types.EProductKemiStockState | null;
      maxSaleDaysPerSeller?: number | null;
      kemiSalesStartAt?: string | null;
      kemiSalesEndAt?: string | null;
      maxBuyPerOrder?: number | null;
      supplyPrice?: number | null;
      isRetailPriceChangeable?: boolean | null;
      discount?: {
        type: Types.EDiscountType;
        price?: number | null;
        rate?: number | null;
      } | null;
      shipping?: {
        shippingChargeType?: Types.EShippingChargeType | null;
        minPriceForFreeShipping?: number | null;
        shippingCharge?: number | null;
        extraChargeForIslandsAndMountains?: number | null;
        shippingLocationType?: Types.EShippingLocationType | null;
      } | null;
      stock?: {
        isInfiniteStock?: boolean | null;
        accumulatedInventory?: number | null;
        availableStock?: number | null;
      } | null;
      options?: Array<{
        sku?: string | null;
        title?: string | null;
        image?: string | null;
        salesState?: Types.EOptionSalesState | null;
        supplyPrice?: number | null;
        suggestedRetailPrice?: number | null;
        requiredNumberOfExtraOption?: number | null;
        isExtraOptionExist?: boolean | null;
        extraOptionDescription?: string | null;
        stock?: {
          isInfiniteStock?: boolean | null;
          accumulatedInventory?: number | null;
          availableStock?: number | null;
        } | null;
        discount?: {
          type: Types.EDiscountType;
          rate?: number | null;
          price?: number | null;
        } | null;
        extraOptions?: Array<{
          title?: string | null;
          sku?: string | null;
          stock?: {
            isInfiniteStock?: boolean | null;
            accumulatedInventory?: number | null;
            availableStock?: number | null;
          } | null;
        }> | null;
      }> | null;
    } | null;
  } | null;
  product?: {
    id: string;
    providerId?: string | null;
    title?: string | null;
    sku?: string | null;
    description?: string | null;
    imageUrls?: Array<string> | null;
    suggestedRetailPrice?: number | null;
    productState?: Types.EProductState | null;
    kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
    kemiStockState?: Types.EProductKemiStockState | null;
    maxSaleDaysPerSeller?: number | null;
    kemiSalesStartAt?: string | null;
    kemiSalesEndAt?: string | null;
    maxBuyPerOrder?: number | null;
    supplyPrice?: number | null;
    isRetailPriceChangeable?: boolean | null;
    discount?: {
      type: Types.EDiscountType;
      price?: number | null;
      rate?: number | null;
    } | null;
    shipping?: {
      shippingChargeType?: Types.EShippingChargeType | null;
      minPriceForFreeShipping?: number | null;
      shippingCharge?: number | null;
      extraChargeForIslandsAndMountains?: number | null;
      shippingLocationType?: Types.EShippingLocationType | null;
    } | null;
    stock?: {
      isInfiniteStock?: boolean | null;
      accumulatedInventory?: number | null;
      availableStock?: number | null;
    } | null;
    options?: Array<{
      sku?: string | null;
      title?: string | null;
      image?: string | null;
      salesState?: Types.EOptionSalesState | null;
      supplyPrice?: number | null;
      suggestedRetailPrice?: number | null;
      requiredNumberOfExtraOption?: number | null;
      isExtraOptionExist?: boolean | null;
      extraOptionDescription?: string | null;
      stock?: {
        isInfiniteStock?: boolean | null;
        accumulatedInventory?: number | null;
        availableStock?: number | null;
      } | null;
      discount?: {
        type: Types.EDiscountType;
        rate?: number | null;
        price?: number | null;
      } | null;
      extraOptions?: Array<{
        title?: string | null;
        sku?: string | null;
        stock?: {
          isInfiniteStock?: boolean | null;
          accumulatedInventory?: number | null;
          availableStock?: number | null;
        } | null;
      }> | null;
    }> | null;
  } | null;
  sellerUser?: {
    id: string;
    linkName: string;
    kemi: {
      id: string;
      linkName: string;
      profileImageUrl?: string | null;
      title: string;
    };
  } | null;
};

export type ProductPartsFragment = {
  id: string;
  providerId?: string | null;
  title?: string | null;
  sku?: string | null;
  description?: string | null;
  imageUrls?: Array<string> | null;
  suggestedRetailPrice?: number | null;
  productState?: Types.EProductState | null;
  kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
  kemiStockState?: Types.EProductKemiStockState | null;
  maxSaleDaysPerSeller?: number | null;
  kemiSalesStartAt?: string | null;
  kemiSalesEndAt?: string | null;
  maxBuyPerOrder?: number | null;
  supplyPrice?: number | null;
  isRetailPriceChangeable?: boolean | null;
  discount?: {
    type: Types.EDiscountType;
    price?: number | null;
    rate?: number | null;
  } | null;
  shipping?: {
    shippingChargeType?: Types.EShippingChargeType | null;
    minPriceForFreeShipping?: number | null;
    shippingCharge?: number | null;
    extraChargeForIslandsAndMountains?: number | null;
    shippingLocationType?: Types.EShippingLocationType | null;
  } | null;
  stock?: {
    isInfiniteStock?: boolean | null;
    accumulatedInventory?: number | null;
    availableStock?: number | null;
  } | null;
  options?: Array<{
    sku?: string | null;
    title?: string | null;
    image?: string | null;
    salesState?: Types.EOptionSalesState | null;
    supplyPrice?: number | null;
    suggestedRetailPrice?: number | null;
    requiredNumberOfExtraOption?: number | null;
    isExtraOptionExist?: boolean | null;
    extraOptionDescription?: string | null;
    stock?: {
      isInfiniteStock?: boolean | null;
      accumulatedInventory?: number | null;
      availableStock?: number | null;
    } | null;
    discount?: {
      type: Types.EDiscountType;
      rate?: number | null;
      price?: number | null;
    } | null;
    extraOptions?: Array<{
      title?: string | null;
      sku?: string | null;
      stock?: {
        isInfiniteStock?: boolean | null;
        accumulatedInventory?: number | null;
        availableStock?: number | null;
      } | null;
    }> | null;
  }> | null;
};

export const ProductPartsFragmentDoc = `
    fragment ProductParts on Product {
  id
  providerId
  title
  sku
  description
  imageUrls
  suggestedRetailPrice
  discount {
    type
    price
    rate
  }
  shipping {
    shippingChargeType
    minPriceForFreeShipping
    shippingCharge
    extraChargeForIslandsAndMountains
    shippingLocationType
  }
  stock {
    isInfiniteStock
    accumulatedInventory
    availableStock
  }
  productState
  kemiSalesPeriodState
  kemiStockState
  maxSaleDaysPerSeller
  kemiSalesStartAt
  kemiSalesEndAt
  maxBuyPerOrder
  supplyPrice
  suggestedRetailPrice
  isRetailPriceChangeable
  options {
    sku
    stock {
      isInfiniteStock
      accumulatedInventory
      availableStock
    }
    title
    image
    salesState
    supplyPrice
    suggestedRetailPrice
    discount {
      type
      rate
      price
    }
    requiredNumberOfExtraOption
    isExtraOptionExist
    extraOptionDescription
    extraOptions {
      title
      sku
      stock {
        isInfiniteStock
        accumulatedInventory
        availableStock
      }
    }
  }
}
    `;
export const CartItemPartsFragmentDoc = `
    fragment CartItemParts on CartItem {
  id
  checked
  details
  productCardId
  productCard {
    id
    userId
    title
    price
    suggestedRetailPrice
    productId
    productDetail {
      ...ProductParts
    }
  }
  productId
  product {
    ...ProductParts
  }
  sellerUserId
  sellerLinkName
  sellerUser {
    id
    linkName
    kemi {
      id
      linkName
      profileImageUrl
      title
    }
  }
  createdAt
  updatedAt
}
    ${ProductPartsFragmentDoc}`;
export const GetCartItemListDocument = `
    query GetCartItemList {
  cartItems {
    ...CartItemParts
  }
}
    ${CartItemPartsFragmentDoc}`;
export const useGetCartItemListQuery = <
  TData = GetCartItemListQueryResponse,
  TError = unknown
>(
  variables?: GetCartItemListQueryVariables,
  options?: UseQueryOptions<GetCartItemListQueryResponse, TError, TData>
) =>
  useQuery<GetCartItemListQueryResponse, TError, TData>(
    variables === undefined
      ? ['GetCartItemList']
      : ['GetCartItemList', variables],
    fetcher<GetCartItemListQueryResponse, GetCartItemListQueryVariables>(
      GetCartItemListDocument,
      variables
    ),
    options
  );

useGetCartItemListQuery.getKey = (variables?: GetCartItemListQueryVariables) =>
  variables === undefined
    ? ['GetCartItemList']
    : ['GetCartItemList', variables];
export const useInfiniteGetCartItemListQuery = <
  TData = GetCartItemListQueryResponse,
  TError = unknown
>(
  variables?: GetCartItemListQueryVariables,
  options?: UseInfiniteQueryOptions<GetCartItemListQueryResponse, TError, TData>
) => {
  return useInfiniteQuery<GetCartItemListQueryResponse, TError, TData>(
    variables === undefined
      ? ['GetCartItemList.infinite']
      : ['GetCartItemList.infinite', variables],
    (metaData) =>
      fetcher<GetCartItemListQueryResponse, GetCartItemListQueryVariables>(
        GetCartItemListDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteGetCartItemListQuery.getKey = (
  variables?: GetCartItemListQueryVariables
) =>
  variables === undefined
    ? ['GetCartItemList.infinite']
    : ['GetCartItemList.infinite', variables];
useGetCartItemListQuery.fetcher = (
  variables?: GetCartItemListQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<GetCartItemListQueryResponse, GetCartItemListQueryVariables>(
    GetCartItemListDocument,
    variables,
    options
  );
export const GetCartItemListCountDocument = `
    query GetCartItemListCount {
  cartItemCount {
    totalCount
  }
}
    `;
export const useGetCartItemListCountQuery = <
  TData = GetCartItemListCountQueryResponse,
  TError = unknown
>(
  variables?: GetCartItemListCountQueryVariables,
  options?: UseQueryOptions<GetCartItemListCountQueryResponse, TError, TData>
) =>
  useQuery<GetCartItemListCountQueryResponse, TError, TData>(
    variables === undefined
      ? ['GetCartItemListCount']
      : ['GetCartItemListCount', variables],
    fetcher<
      GetCartItemListCountQueryResponse,
      GetCartItemListCountQueryVariables
    >(GetCartItemListCountDocument, variables),
    options
  );

useGetCartItemListCountQuery.getKey = (
  variables?: GetCartItemListCountQueryVariables
) =>
  variables === undefined
    ? ['GetCartItemListCount']
    : ['GetCartItemListCount', variables];
export const useInfiniteGetCartItemListCountQuery = <
  TData = GetCartItemListCountQueryResponse,
  TError = unknown
>(
  variables?: GetCartItemListCountQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetCartItemListCountQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<GetCartItemListCountQueryResponse, TError, TData>(
    variables === undefined
      ? ['GetCartItemListCount.infinite']
      : ['GetCartItemListCount.infinite', variables],
    (metaData) =>
      fetcher<
        GetCartItemListCountQueryResponse,
        GetCartItemListCountQueryVariables
      >(GetCartItemListCountDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteGetCartItemListCountQuery.getKey = (
  variables?: GetCartItemListCountQueryVariables
) =>
  variables === undefined
    ? ['GetCartItemListCount.infinite']
    : ['GetCartItemListCount.infinite', variables];
useGetCartItemListCountQuery.fetcher = (
  variables?: GetCartItemListCountQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    GetCartItemListCountQueryResponse,
    GetCartItemListCountQueryVariables
  >(GetCartItemListCountDocument, variables, options);
export const GetCartItemInfoDocument = `
    query GetCartItemInfo($cardIds: [String!]!) {
  cards(ids: $cardIds) {
    ... on ProductCard {
      id
      user {
        id
        kemi {
          linkName
          profileImageUrl
          title
        }
      }
      title
      price
      suggestedRetailPrice
      productId
      productDetail {
        ...ProductParts
      }
    }
  }
}
    ${ProductPartsFragmentDoc}`;
export const useGetCartItemInfoQuery = <
  TData = GetCartItemInfoQueryResponse,
  TError = unknown
>(
  variables: GetCartItemInfoQueryVariables,
  options?: UseQueryOptions<GetCartItemInfoQueryResponse, TError, TData>
) =>
  useQuery<GetCartItemInfoQueryResponse, TError, TData>(
    ['GetCartItemInfo', variables],
    fetcher<GetCartItemInfoQueryResponse, GetCartItemInfoQueryVariables>(
      GetCartItemInfoDocument,
      variables
    ),
    options
  );

useGetCartItemInfoQuery.getKey = (variables: GetCartItemInfoQueryVariables) => [
  'GetCartItemInfo',
  variables,
];
export const useInfiniteGetCartItemInfoQuery = <
  TData = GetCartItemInfoQueryResponse,
  TError = unknown
>(
  variables: GetCartItemInfoQueryVariables,
  options?: UseInfiniteQueryOptions<GetCartItemInfoQueryResponse, TError, TData>
) => {
  return useInfiniteQuery<GetCartItemInfoQueryResponse, TError, TData>(
    ['GetCartItemInfo.infinite', variables],
    (metaData) =>
      fetcher<GetCartItemInfoQueryResponse, GetCartItemInfoQueryVariables>(
        GetCartItemInfoDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteGetCartItemInfoQuery.getKey = (
  variables: GetCartItemInfoQueryVariables
) => ['GetCartItemInfo.infinite', variables];
useGetCartItemInfoQuery.fetcher = (
  variables: GetCartItemInfoQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<GetCartItemInfoQueryResponse, GetCartItemInfoQueryVariables>(
    GetCartItemInfoDocument,
    variables,
    options
  );
export const AddCartItemListDocument = `
    mutation AddCartItemList($cartInput: AddToCartInput!) {
  addToCart(cartInput: $cartInput) {
    totalCount
    items {
      ...CartItemParts
    }
  }
}
    ${CartItemPartsFragmentDoc}`;
export const useAddCartItemListMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    AddCartItemListMutationResponse,
    TError,
    AddCartItemListMutationVariables,
    TContext
  >
) =>
  useMutation<
    AddCartItemListMutationResponse,
    TError,
    AddCartItemListMutationVariables,
    TContext
  >(
    ['AddCartItemList'],
    (variables?: AddCartItemListMutationVariables) =>
      fetcher<
        AddCartItemListMutationResponse,
        AddCartItemListMutationVariables
      >(AddCartItemListDocument, variables)(),
    options
  );
useAddCartItemListMutation.getKey = () => ['AddCartItemList'];

useAddCartItemListMutation.fetcher = (
  variables: AddCartItemListMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<AddCartItemListMutationResponse, AddCartItemListMutationVariables>(
    AddCartItemListDocument,
    variables,
    options
  );
export const UpdateCartItemDocument = `
    mutation UpdateCartItem($id: String!, $cartItem: CartItemInput!) {
  updateCartItem(id: $id, cartItem: $cartItem) {
    id
    updatedCartItem {
      ...CartItemParts
    }
  }
}
    ${CartItemPartsFragmentDoc}`;
export const useUpdateCartItemMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateCartItemMutationResponse,
    TError,
    UpdateCartItemMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateCartItemMutationResponse,
    TError,
    UpdateCartItemMutationVariables,
    TContext
  >(
    ['UpdateCartItem'],
    (variables?: UpdateCartItemMutationVariables) =>
      fetcher<UpdateCartItemMutationResponse, UpdateCartItemMutationVariables>(
        UpdateCartItemDocument,
        variables
      )(),
    options
  );
useUpdateCartItemMutation.getKey = () => ['UpdateCartItem'];

useUpdateCartItemMutation.fetcher = (
  variables: UpdateCartItemMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<UpdateCartItemMutationResponse, UpdateCartItemMutationVariables>(
    UpdateCartItemDocument,
    variables,
    options
  );
export const UpdateCartItemsCheckedDocument = `
    mutation UpdateCartItemsChecked($cartItemsChecked: UpdateCartItemsChecked!) {
  updateCartItemsChecked(cartItemsChecked: $cartItemsChecked) {
    itemIDs
  }
}
    `;
export const useUpdateCartItemsCheckedMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateCartItemsCheckedMutationResponse,
    TError,
    UpdateCartItemsCheckedMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateCartItemsCheckedMutationResponse,
    TError,
    UpdateCartItemsCheckedMutationVariables,
    TContext
  >(
    ['UpdateCartItemsChecked'],
    (variables?: UpdateCartItemsCheckedMutationVariables) =>
      fetcher<
        UpdateCartItemsCheckedMutationResponse,
        UpdateCartItemsCheckedMutationVariables
      >(UpdateCartItemsCheckedDocument, variables)(),
    options
  );
useUpdateCartItemsCheckedMutation.getKey = () => ['UpdateCartItemsChecked'];

useUpdateCartItemsCheckedMutation.fetcher = (
  variables: UpdateCartItemsCheckedMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    UpdateCartItemsCheckedMutationResponse,
    UpdateCartItemsCheckedMutationVariables
  >(UpdateCartItemsCheckedDocument, variables, options);
export const DeleteCartItemListDocument = `
    mutation DeleteCartItemList($cartInput: RemoveFromCartInput!) {
  removeFromCart(cartInput: $cartInput) {
    ids
  }
}
    `;
export const useDeleteCartItemListMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteCartItemListMutationResponse,
    TError,
    DeleteCartItemListMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteCartItemListMutationResponse,
    TError,
    DeleteCartItemListMutationVariables,
    TContext
  >(
    ['DeleteCartItemList'],
    (variables?: DeleteCartItemListMutationVariables) =>
      fetcher<
        DeleteCartItemListMutationResponse,
        DeleteCartItemListMutationVariables
      >(DeleteCartItemListDocument, variables)(),
    options
  );
useDeleteCartItemListMutation.getKey = () => ['DeleteCartItemList'];

useDeleteCartItemListMutation.fetcher = (
  variables: DeleteCartItemListMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    DeleteCartItemListMutationResponse,
    DeleteCartItemListMutationVariables
  >(DeleteCartItemListDocument, variables, options);
