const randomCovers = [
  'https://asset.kemi.io/default-images/kemi_default_cover_01.jpg',
  'https://asset.kemi.io/default-images/kemi_default_cover_02.jpg',
  'https://asset.kemi.io/default-images/kemi_default_cover_03.jpg',
  'https://asset.kemi.io/default-images/kemi_default_cover_04.jpg',
  'https://asset.kemi.io/default-images/kemi_default_cover_05.jpg',
  'https://asset.kemi.io/default-images/kemi_default_cover_06.jpg',
];

const randomProfiles = [
  'https://asset.kemi.io/default-images/kemi_default_profile_01.jpg',
  'https://asset.kemi.io/default-images/kemi_default_profile_02.jpg',
  'https://asset.kemi.io/default-images/kemi_default_profile_03.jpg',
  'https://asset.kemi.io/default-images/kemi_default_profile_04.jpg',
  'https://asset.kemi.io/default-images/kemi_default_profile_05.jpg',
];

export const getRandomCoverImage = () => {
  return randomCovers[getRandomIndex(randomCovers.length)];
};

export const getRandomProfileImage = () => {
  return randomProfiles[getRandomIndex(randomProfiles.length)];
};

export const getRandomIndex = (length: number) => {
  return Math.floor(Math.random() * length);
};

export const checkDefaultImage = (src: string) => {
  return randomCovers.includes(src) || randomProfiles.includes(src);
};
