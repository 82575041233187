import classNames from 'classnames/bind';
import {
  createElement,
  ReactHTML,
  PropsWithChildren,
  forwardRef,
  AllHTMLAttributes,
} from 'react';

import type { Font, Color } from '../types';

import styles from './Text.module.scss';

const cx = classNames.bind(styles);

export type TextProps = AllHTMLAttributes<Element> &
  PropsWithChildren<{
    type?: keyof ReactHTML;
    font?: Font;
    color?: Color;
    className?: string;
  }>;

// eslint-disable-next-line react/display-name
const Text = forwardRef(
  (
    { type = 'span', font, color, className, children, ...rest }: TextProps,
    ref
  ) => {
    const textStyleOption = {
      [`font_${font}`]: font,
      [`color_${color}`]: color,
    };

    return createElement(
      type,
      { ref, className: cx('common', className, textStyleOption), ...rest },
      children
    );
  }
);

export default Text;
