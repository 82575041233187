import { NextRouter, useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import kemiStore from '@global/store/kemiStore';
import { UserInteractionType } from '@global/types';
import ROUTE, { ROUTE_QUERY } from '@routers';

const usePageOpenEvent = () => {
  const router = useRouter();
  const [isVisited, setIsVisited] = useState(false);
  const isKemiPage = router.route.startsWith(ROUTE.kemi);

  useEffect(() => {
    logKemiOpenEvent(router);
    logKpgOpenEvent(router);

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && isKemiPage) {
        logVisitKemiEvent(router);
      }
    };

    if (isKemiPage && !isVisited) {
      logVisitKemiEvent(router);
      setIsVisited(true);
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [router.asPath]);
};

const logVisitKemiEvent = async (router: NextRouter) => {
  const linkName = router.query[ROUTE_QUERY.linkName];

  if (typeof linkName !== 'string') {
    return;
  }

  const { getKemiId } = kemiStore.getState();
  const kemiId = await getKemiId(linkName);

  logFirebase(
    UserInteractionType.PAGE_IN,
    EVENT_TAG.GLOBAL_PAGE_OPEN.VISIT_KEMI,
    {
      kemi_id: kemiId,
      path: router.asPath,
    }
  );
};

const logKemiOpenEvent = async (router: NextRouter) => {
  const linkName = router.query[ROUTE_QUERY.linkName];

  if (typeof linkName !== 'string') {
    return;
  }

  const { getKemiId } = kemiStore.getState();
  const kemiId = await getKemiId(linkName);

  if (router.route.startsWith(ROUTE.kemi)) {
    logFirebase(UserInteractionType.PAGE_IN, EVENT_TAG.GLOBAL_PAGE_OPEN.KEMI, {
      kemi_id: kemiId,
      path: router.asPath,
    });
  }
};

const logKpgOpenEvent = (router: NextRouter) => {
  if (router.route.startsWith(ROUTE.mykemi.index)) {
    logFirebase(UserInteractionType.PAGE_IN, EVENT_TAG.GLOBAL_PAGE_OPEN.KPG, {
      path: router.asPath,
    });
  }
};

export default usePageOpenEvent;
