import { useContext, useMemo } from 'react';
import { UAParser } from 'ua-parser-js';

import ServerSideContext from '@shared/contexts/ServerSideContext';

const useUserAgent = () => {
  const { userAgent: ssrUserAgent } = useContext(ServerSideContext);

  const userAgent = useMemo(() => {
    if (typeof window === 'undefined') {
      return ssrUserAgent;
    }

    return navigator.userAgent;
  }, [ssrUserAgent]);

  const uaParser = useMemo(() => {
    return new UAParser(userAgent);
  }, [userAgent]);

  const result = useMemo(() => {
    const deviceType = uaParser.getDevice().type;

    const isDesktop = deviceType !== 'mobile' && deviceType !== 'tablet';

    return {
      isDesktop,
    };
  }, [uaParser]);

  return result;
};

export default useUserAgent;
