import Button from '@global/components/atoms/Button';
import Image from '@global/components/atoms/Image';
import { EVENT_TAG } from '@global/constants';
import { useLogFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import thinkingKemi from '@static/media/kemi-thinking.svg';
import { styled } from '@styles/stitches.config';

const InternalError = () => {
  useLogFirebase(
    UserInteractionType.PAGE_IN,
    EVENT_TAG.GENERIC_ERROR.SCREEN_OPEN_GENERIC_ERROR
  );

  return (
    <Container>
      <ImageContainer>
        <Image src={thinkingKemi} alt={''} />
      </ImageContainer>
      <Title>{i18n.t('k_hmm_something_went_wrong')}</Title>
      <Description>
        {i18n.t('k_an_error_has_occurred_please_try_again_later')}
      </Description>
      <Button
        onClick={() => {
          location.href = '/';
        }}
      >
        {i18n.t('k_refresh')}
      </Button>
    </Container>
  );
};

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: 16,
  textAlign: 'center',
});

const ImageContainer = styled('div', {
  marginBottom: 16,
});

const Title = styled('span', {
  fontType: 'bodyBold',
  marginBottom: 16,
});

const Description = styled('span', {
  fontType: 'bodyRegular',
  marginBottom: 24,
});

export default InternalError;
