import pino from 'pino';
import { createPinoBrowserSend, createWriteStream } from 'pino-logflare';

const apiKey = process.env.NEXT_PUBLIC_LOGFLARE_API_KEY as string;
const sourceToken =
  process.env.NEXT_PUBLIC_APP_ENV === 'prod'
    ? '487c577f-38e2-447f-910f-8f0725ccf44f' // production
    : '5d3c67db-3e51-4564-8aee-c626256d626c'; // non-production

const stream = createWriteStream({ apiKey, sourceToken });
const send = createPinoBrowserSend({ apiKey, sourceToken });

/**
 * 다솜님께서 로깅 v2를 만들어 주시기 전까지 임시로 사용하는 로거입니다.
 * 현재 인스턴스는 order 전용으로 새로 생성하여 쌓고 있습니다.
 */
export const orderLogger = pino(
  {
    enabled: true,
    browser: {
      transmit: { send },
    },
  },
  stream
);
