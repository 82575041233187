const Request = ({ ...rest }) => {
  const color = rest.type === 'linedWhite' ? 'white' : '#303030';

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={
          'M3 20V18.6499C3 18.2547 3.23273 17.8966 3.59386 17.7361L7.5 16C7.5 16 6.73509 17.2351 8 18.5C9 19.5 9.83333 19.3333 10.5 19L9.77639 20.4472C9.607 20.786 9.26074 21 8.88197 21H4C3.44772 21 3 20.5523 3 20Z'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M21.0015 20V18.6499C21.0015 18.2547 20.7687 17.8966 20.4076 17.7361L16.5015 16C16.5015 16 17.2664 17.2351 16.0015 18.5C15.0015 19.5 14.1681 19.3333 13.5015 19L14.2251 20.4472C14.3945 20.786 14.7407 21 15.1195 21H20.0015C20.5537 21 21.0015 20.5523 21.0015 20Z'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M7.5 16L10 5C10.5 2.5 12 3 12 4.99999C12 5.79999 12 13 12 17C12 17.6667 12.1 19 10.5 19'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M16.5015 16L14.0015 5C13.5015 2.5 12.0015 3 12.0015 4.99999C12.0015 5.79999 12.0015 13 12.0015 17C12.0015 17.6667 11.9015 19 13.5015 19'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M12 7C12 5 10.5 5 10 12'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M12.0015 7C12.0015 5 13.5 5 14.0015 12'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default Request;
