import * as Types from 'schema/types';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type GetCountPerCommissionOrderFiltersQueryVariables = Types.Exact<{
  requestInput: Types.CountPerCommissionOrderFiltersInput;
  receiveInput: Types.CountPerCommissionOrderFiltersInput;
}>;

export type GetCountPerCommissionOrderFiltersQueryResponse = {
  requestCommissions?: {
    filters: Array<{ count: number; filter: Types.ECommissionOrdersFilterBy }>;
  } | null;
  receiveCommissions?: {
    filters: Array<{ count: number; filter: Types.ECommissionOrdersFilterBy }>;
  } | null;
};

export const GetCountPerCommissionOrderFiltersDocument = `
    query GetCountPerCommissionOrderFilters($requestInput: CountPerCommissionOrderFiltersInput!, $receiveInput: CountPerCommissionOrderFiltersInput!) {
  requestCommissions: countPerCommissionOrderFilters(input: $requestInput) {
    filters {
      count
      filter
    }
  }
  receiveCommissions: countPerCommissionOrderFilters(input: $receiveInput) {
    filters {
      count
      filter
    }
  }
}
    `;
export const useGetCountPerCommissionOrderFiltersQuery = <
  TData = GetCountPerCommissionOrderFiltersQueryResponse,
  TError = unknown
>(
  variables: GetCountPerCommissionOrderFiltersQueryVariables,
  options?: UseQueryOptions<
    GetCountPerCommissionOrderFiltersQueryResponse,
    TError,
    TData
  >
) =>
  useQuery<GetCountPerCommissionOrderFiltersQueryResponse, TError, TData>(
    ['GetCountPerCommissionOrderFilters', variables],
    fetcher<
      GetCountPerCommissionOrderFiltersQueryResponse,
      GetCountPerCommissionOrderFiltersQueryVariables
    >(GetCountPerCommissionOrderFiltersDocument, variables),
    options
  );

useGetCountPerCommissionOrderFiltersQuery.getKey = (
  variables: GetCountPerCommissionOrderFiltersQueryVariables
) => ['GetCountPerCommissionOrderFilters', variables];
export const useInfiniteGetCountPerCommissionOrderFiltersQuery = <
  TData = GetCountPerCommissionOrderFiltersQueryResponse,
  TError = unknown
>(
  variables: GetCountPerCommissionOrderFiltersQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetCountPerCommissionOrderFiltersQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<
    GetCountPerCommissionOrderFiltersQueryResponse,
    TError,
    TData
  >(
    ['GetCountPerCommissionOrderFilters.infinite', variables],
    (metaData) =>
      fetcher<
        GetCountPerCommissionOrderFiltersQueryResponse,
        GetCountPerCommissionOrderFiltersQueryVariables
      >(GetCountPerCommissionOrderFiltersDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteGetCountPerCommissionOrderFiltersQuery.getKey = (
  variables: GetCountPerCommissionOrderFiltersQueryVariables
) => ['GetCountPerCommissionOrderFilters.infinite', variables];
useGetCountPerCommissionOrderFiltersQuery.fetcher = (
  variables: GetCountPerCommissionOrderFiltersQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    GetCountPerCommissionOrderFiltersQueryResponse,
    GetCountPerCommissionOrderFiltersQueryVariables
  >(GetCountPerCommissionOrderFiltersDocument, variables, options);
