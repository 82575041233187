import * as Types from 'schema/types';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type MykemiForKemiStoreQueryVariables = Types.Exact<{
  linkName: Types.Scalars['String'];
}>;

export type MykemiForKemiStoreQueryResponse = { kemi?: { id: string } | null };

export const MykemiForKemiStoreDocument = `
    query MykemiForKemiStore($linkName: String!) {
  kemi(linkName: $linkName) {
    id
  }
}
    `;
export const useMykemiForKemiStoreQuery = <
  TData = MykemiForKemiStoreQueryResponse,
  TError = unknown
>(
  variables: MykemiForKemiStoreQueryVariables,
  options?: UseQueryOptions<MykemiForKemiStoreQueryResponse, TError, TData>
) =>
  useQuery<MykemiForKemiStoreQueryResponse, TError, TData>(
    ['MykemiForKemiStore', variables],
    fetcher<MykemiForKemiStoreQueryResponse, MykemiForKemiStoreQueryVariables>(
      MykemiForKemiStoreDocument,
      variables
    ),
    options
  );

useMykemiForKemiStoreQuery.getKey = (
  variables: MykemiForKemiStoreQueryVariables
) => ['MykemiForKemiStore', variables];
export const useInfiniteMykemiForKemiStoreQuery = <
  TData = MykemiForKemiStoreQueryResponse,
  TError = unknown
>(
  variables: MykemiForKemiStoreQueryVariables,
  options?: UseInfiniteQueryOptions<
    MykemiForKemiStoreQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<MykemiForKemiStoreQueryResponse, TError, TData>(
    ['MykemiForKemiStore.infinite', variables],
    (metaData) =>
      fetcher<
        MykemiForKemiStoreQueryResponse,
        MykemiForKemiStoreQueryVariables
      >(MykemiForKemiStoreDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteMykemiForKemiStoreQuery.getKey = (
  variables: MykemiForKemiStoreQueryVariables
) => ['MykemiForKemiStore.infinite', variables];
useMykemiForKemiStoreQuery.fetcher = (
  variables: MykemiForKemiStoreQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<MykemiForKemiStoreQueryResponse, MykemiForKemiStoreQueryVariables>(
    MykemiForKemiStoreDocument,
    variables,
    options
  );
