import classnames from 'classnames/bind';
import React, {
  ChangeEvent,
  useState,
  useEffect,
  useRef,
  MouseEvent,
  ReactNode,
} from 'react';

import Box from '../Box';
import Text from '../Text';
import { Color } from '../types';

import styles from './Input.module.scss';

import CloseCircle from '@static/svg/CloseCircle';

const cx = classnames.bind(styles);

export type InputProps = {
  value: string;
  type?: string;
  pattern?: string;
  placeholder?: string;
  leftIcon?: ReactNode;
  fixedPlaceholder?: string;
  fixedPlaceholderColor?: Color;
  onChange?: (value: string, name?: string) => void;
  onBlur?: () => void;
  errorBorder?: boolean;
  maxLength?: number;
  clearIcon?: boolean;
  name?: string;
  disabled?: boolean;
  disabledBorder?: boolean;
  onClear?: () => void;
  onClick?: () => void;
  onFocus?: () => void;
  autocapitalize?: 'on' | 'off';
  suffix?: string;
  valueAlign?: 'left' | 'right' | 'center';
  customIcon?: JSX.Element;
  label?: string;
  className?: string;
};

const Input = ({
  value = '',
  type,
  pattern,
  placeholder,
  leftIcon,
  fixedPlaceholder,
  fixedPlaceholderColor,
  onChange,
  onBlur,
  errorBorder,
  maxLength,
  clearIcon,
  onClear,
  onClick,
  onFocus,
  disabled,
  disabledBorder,
  name,
  autocapitalize,
  suffix,
  valueAlign,
  customIcon,
  label,
  className,
}: InputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [_hasError, setHasError] = useState(errorBorder);
  const inputRef = useRef<HTMLInputElement>(null);

  const containerStyleOption = {
    error: errorBorder,
    focus: isFocused,
  };

  const handleInputContainerClick = () => {
    onClick?.();

    inputRef.current?.focus();
  };

  const handleInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    // onChange시에 wheel event라면 e.nativeEvent.inputType은 undefined가 나옴
    // type === 'number'일때 마우스 휠 사용시 숫자가 바뀌는 현상을 막으려고 사용함.
    const { value, name } = e.currentTarget;

    if (type === 'number') {
      const onlyNumberValue = value.replace(/[^\d]/g, '');
      onChange?.(onlyNumberValue, name);
      return;
    }

    if (type === 'tel') {
      const onlyNumberValue = value.replace(/[^\d]/g, '');
      const lastNumber = parseInt(onlyNumberValue[onlyNumberValue.length - 1]);

      if (onlyNumberValue.length && isNaN(lastNumber)) return;

      onChange?.(onlyNumberValue, name);
      return;
    }

    onChange?.(value, name);
  };

  const handleInputContainerMouseDown = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    if (e.target !== inputRef.current) {
      e.preventDefault();
    }
  };

  const handleClear = () => {
    onChange?.('');
    onClear?.();
  };

  useEffect(() => {
    if (!maxLength) return;

    if (value.length > maxLength && !_hasError) {
      return setHasError(true);
    }

    if (_hasError && value.length <= maxLength) {
      return setHasError(false);
    }
  }, [value, maxLength, _hasError]);

  return (
    <Box
      className={cx('container', containerStyleOption, className, {
        disabled,
        disabledBorder,
      })}
      onClick={handleInputContainerClick}
      onMouseDown={handleInputContainerMouseDown}
    >
      {leftIcon && <div className={cx('leftIcon')}>{leftIcon}</div>}
      {fixedPlaceholder && (
        <Text
          font={'bodyRegular'}
          className={cx('fixedPlaceholder')}
          color={fixedPlaceholderColor || 'grey100'}
        >
          {fixedPlaceholder}
        </Text>
      )}
      <Box className={cx('inputWrapper')}>
        <input
          ref={inputRef}
          aria-label={label}
          autoCapitalize={autocapitalize}
          disabled={disabled}
          type={type}
          pattern={pattern}
          className={cx('input', { right: valueAlign === 'right' })}
          name={name}
          value={value}
          onChange={handleInputValueChange}
          placeholder={placeholder}
          onFocus={() => {
            onFocus?.();
            setIsFocused(true);
          }}
          onBlur={() => {
            onBlur?.();
            setIsFocused(false);
          }}
        />
        {suffix && (
          <Box className={cx('suffix')}>
            <Text font={'bodyRegular'} color={'grey100'}>
              {suffix}
            </Text>
          </Box>
        )}
      </Box>
      {clearIcon && (
        <CloseCircle className={cx('rightIcon')} onClick={handleClear} />
      )}
      {customIcon}
      {maxLength && (
        <Text type={'div'} className={cx('rightIcon')} font={'labelRegular'}>
          {value.length}
          {'/'}
          {maxLength}
        </Text>
      )}
    </Box>
  );
};

export default Input;
