import classNames from 'classnames/bind';
import { PropsWithChildren, ReactNode } from 'react';

import Box from '../../atoms/Box';
import Text from '../../atoms/Text';

import styles from './LabelBox.module.scss';

const cx = classNames.bind(styles);

export type LabelBoxProps = PropsWithChildren<{
  label?: string;
  labelDescription?: string;
  labelOption?: string | ReactNode;
  labelTextBelow?: string;
  errors?: string[] | string;
  required?: boolean;
  className?: string;
}>;

const LabelBox = ({
  label,
  labelDescription,
  labelOption,
  labelTextBelow,
  errors,
  className,
  required,
  children,
}: LabelBoxProps) => {
  const hasError = Boolean(errors && errors.length);

  return (
    <Box className={cx('container', className)}>
      {label && (
        <Box className={cx('labelContainer')}>
          <Box className={cx('gridRow')}>
            <Text type={'h3'} font={'bodyRegular'} className={cx({ required })}>
              {label}
            </Text>
            <Text className={cx('description')}>{labelDescription}</Text>
          </Box>
          {labelOption && labelOption}
        </Box>
      )}
      {children}
      {hasError && <Error errors={errors as string[]} />}
      {labelTextBelow && (
        <Text font={'captionRegular'} color={'grey100'}>
          {labelTextBelow}
        </Text>
      )}
    </Box>
  );
};

type ErrorProps = {
  errors: string[] | string;
};

const Error = ({ errors }: ErrorProps) => {
  return (
    <Box type={'ul'} className={cx('errors')}>
      {typeof errors === 'string' ? (
        <Text color={'red100'} font={'captionRegular'}>
          {errors}
        </Text>
      ) : (
        errors.map((error, i) => {
          return (
            <Text key={i} color={'red100'} font={'captionRegular'}>
              {error}
            </Text>
          );
        })
      )}
    </Box>
  );
};

export default LabelBox;
