import * as Types from 'schema/types';

import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type OrderQueryVariables = Types.Exact<{
  orderId: Types.Scalars['String'];
}>;

export type OrderQueryResponse = {
  order?: {
    id: string;
    paymentStatus?: Types.EPaymentStatus | null;
    memo?: string | null;
    PCCC?: string | null;
    paymentMethod?: Types.EOrderPaymentMethod | null;
    cardNumber?: string | null;
    cardName?: string | null;
    vbankNum?: string | null;
    vbankName?: string | null;
    vbankHolder?: string | null;
    vbankDate?: string | null;
    paidAt?: string | null;
    createdAt: string;
    orderer?: {
      ordererName?: string | null;
      ordererPhone?: string | null;
      ordererEmail?: string | null;
    } | null;
    receiver?: {
      receiverName?: string | null;
      receiverPhone?: string | null;
      receiverAddressCode?: string | null;
      receiverAddress1?: string | null;
      receiverAddress2?: string | null;
      isIslandsOrMountains?: boolean | null;
      isJeju?: boolean | null;
    } | null;
    orderPriceDetail?: {
      totalShippingPrice?: number | null;
      totalPrice?: number | null;
    } | null;
    orderLineItems?: Array<{
      id: string;
      orderId?: string | null;
      orderLineItemDetail?: string | null;
      productTitle?: string | null;
      productOptionTitles?: Array<string> | null;
      orderLineItemStatus: Types.EOrderLineItemStatus;
      productCardId?: string | null;
      productId?: string | null;
      providerId?: string | null;
      orderLineItemPriceDetail?: {
        recommendedPrice?: number | null;
        discountPrice?: number | null;
        discountedPrice?: number | null;
        shippingPrice?: number | null;
      } | null;
      productCard?: {
        id: string;
        title: string;
        price: number;
        productDetail?: {
          kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
        } | null;
      } | null;
      product?: {
        sku?: string | null;
        title?: string | null;
        imageUrls?: Array<string> | null;
        shipping?: {
          shippingLocationType?: Types.EShippingLocationType | null;
          extraChargeForIslandsAndMountains?: number | null;
          extraChargeForJeju?: number | null;
        } | null;
        options?: Array<{
          sku?: string | null;
          image?: string | null;
          extraOptions?: Array<{ sku?: string | null }> | null;
        }> | null;
      } | null;
      sellerUser?: {
        linkName: string;
        kemi: { id: string; title: string; profileImageUrl?: string | null };
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateOrderMutationVariables = Types.Exact<{
  orderId: Types.Scalars['String'];
  orderInfo: Types.UpdateOrderInput;
}>;

export type UpdateOrderMutationResponse = {
  updateOrder?: { id: string } | null;
};

export type OrderItemPeriodStateQueryVariables = Types.Exact<{
  orderId: Types.Scalars['String'];
}>;

export type OrderItemPeriodStateQueryResponse = {
  order?: {
    orderLineItems?: Array<{
      productCard?: {
        productDetail?: {
          kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type AvailableStockQueryVariables = Types.Exact<{
  stocks: Array<Types.HasEnoughStocksInput> | Types.HasEnoughStocksInput;
}>;

export type AvailableStockQueryResponse = {
  hasEnoughStocks: Array<{ sku?: string | null; available?: boolean | null }>;
};

export const OrderDocument = `
    query Order($orderId: String!) {
  order(id: $orderId) {
    id
    paymentStatus
    orderer {
      ordererName
      ordererPhone
      ordererEmail
    }
    receiver {
      receiverName
      receiverPhone
      receiverAddressCode
      receiverAddress1
      receiverAddress2
      isIslandsOrMountains
      isJeju
    }
    memo
    orderPriceDetail {
      totalShippingPrice
      totalPrice
    }
    orderLineItems {
      id
      orderId
      orderLineItemDetail
      productTitle
      productOptionTitles
      orderLineItemStatus
      orderLineItemPriceDetail {
        recommendedPrice
        discountPrice
        discountedPrice
        shippingPrice
      }
      productCardId
      productCard {
        id
        title
        price
        productDetail {
          kemiSalesPeriodState
        }
      }
      productId
      product {
        sku
        title
        imageUrls
        shipping {
          shippingLocationType
          extraChargeForIslandsAndMountains
          extraChargeForJeju
        }
        options {
          sku
          image
          extraOptions {
            sku
          }
        }
      }
      sellerUser {
        linkName
        kemi {
          id
          title
          profileImageUrl
        }
      }
      providerId
    }
    PCCC
    paymentMethod
    cardNumber
    cardName
    vbankNum
    vbankName
    vbankHolder
    vbankDate
    paidAt
    createdAt
  }
}
    `;
export const useOrderQuery = <TData = OrderQueryResponse, TError = unknown>(
  variables: OrderQueryVariables,
  options?: UseQueryOptions<OrderQueryResponse, TError, TData>
) =>
  useQuery<OrderQueryResponse, TError, TData>(
    ['Order', variables],
    fetcher<OrderQueryResponse, OrderQueryVariables>(OrderDocument, variables),
    options
  );

useOrderQuery.getKey = (variables: OrderQueryVariables) => ['Order', variables];
export const useInfiniteOrderQuery = <
  TData = OrderQueryResponse,
  TError = unknown
>(
  variables: OrderQueryVariables,
  options?: UseInfiniteQueryOptions<OrderQueryResponse, TError, TData>
) => {
  return useInfiniteQuery<OrderQueryResponse, TError, TData>(
    ['Order.infinite', variables],
    (metaData) =>
      fetcher<OrderQueryResponse, OrderQueryVariables>(OrderDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteOrderQuery.getKey = (variables: OrderQueryVariables) => [
  'Order.infinite',
  variables,
];
useOrderQuery.fetcher = (
  variables: OrderQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<OrderQueryResponse, OrderQueryVariables>(
    OrderDocument,
    variables,
    options
  );
export const UpdateOrderDocument = `
    mutation UpdateOrder($orderId: String!, $orderInfo: UpdateOrderInput!) {
  updateOrder(orderId: $orderId, info: $orderInfo) {
    id
  }
}
    `;
export const useUpdateOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateOrderMutationResponse,
    TError,
    UpdateOrderMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateOrderMutationResponse,
    TError,
    UpdateOrderMutationVariables,
    TContext
  >(
    ['UpdateOrder'],
    (variables?: UpdateOrderMutationVariables) =>
      fetcher<UpdateOrderMutationResponse, UpdateOrderMutationVariables>(
        UpdateOrderDocument,
        variables
      )(),
    options
  );
useUpdateOrderMutation.getKey = () => ['UpdateOrder'];

useUpdateOrderMutation.fetcher = (
  variables: UpdateOrderMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<UpdateOrderMutationResponse, UpdateOrderMutationVariables>(
    UpdateOrderDocument,
    variables,
    options
  );
export const OrderItemPeriodStateDocument = `
    query OrderItemPeriodState($orderId: String!) {
  order(id: $orderId) {
    orderLineItems {
      productCard {
        productDetail {
          kemiSalesPeriodState
        }
      }
    }
  }
}
    `;
export const useOrderItemPeriodStateQuery = <
  TData = OrderItemPeriodStateQueryResponse,
  TError = unknown
>(
  variables: OrderItemPeriodStateQueryVariables,
  options?: UseQueryOptions<OrderItemPeriodStateQueryResponse, TError, TData>
) =>
  useQuery<OrderItemPeriodStateQueryResponse, TError, TData>(
    ['OrderItemPeriodState', variables],
    fetcher<
      OrderItemPeriodStateQueryResponse,
      OrderItemPeriodStateQueryVariables
    >(OrderItemPeriodStateDocument, variables),
    options
  );

useOrderItemPeriodStateQuery.getKey = (
  variables: OrderItemPeriodStateQueryVariables
) => ['OrderItemPeriodState', variables];
export const useInfiniteOrderItemPeriodStateQuery = <
  TData = OrderItemPeriodStateQueryResponse,
  TError = unknown
>(
  variables: OrderItemPeriodStateQueryVariables,
  options?: UseInfiniteQueryOptions<
    OrderItemPeriodStateQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<OrderItemPeriodStateQueryResponse, TError, TData>(
    ['OrderItemPeriodState.infinite', variables],
    (metaData) =>
      fetcher<
        OrderItemPeriodStateQueryResponse,
        OrderItemPeriodStateQueryVariables
      >(OrderItemPeriodStateDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteOrderItemPeriodStateQuery.getKey = (
  variables: OrderItemPeriodStateQueryVariables
) => ['OrderItemPeriodState.infinite', variables];
useOrderItemPeriodStateQuery.fetcher = (
  variables: OrderItemPeriodStateQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    OrderItemPeriodStateQueryResponse,
    OrderItemPeriodStateQueryVariables
  >(OrderItemPeriodStateDocument, variables, options);
export const AvailableStockDocument = `
    query AvailableStock($stocks: [HasEnoughStocksInput!]!) {
  hasEnoughStocks(stocks: $stocks) {
    sku
    available
  }
}
    `;
export const useAvailableStockQuery = <
  TData = AvailableStockQueryResponse,
  TError = unknown
>(
  variables: AvailableStockQueryVariables,
  options?: UseQueryOptions<AvailableStockQueryResponse, TError, TData>
) =>
  useQuery<AvailableStockQueryResponse, TError, TData>(
    ['AvailableStock', variables],
    fetcher<AvailableStockQueryResponse, AvailableStockQueryVariables>(
      AvailableStockDocument,
      variables
    ),
    options
  );

useAvailableStockQuery.getKey = (variables: AvailableStockQueryVariables) => [
  'AvailableStock',
  variables,
];
export const useInfiniteAvailableStockQuery = <
  TData = AvailableStockQueryResponse,
  TError = unknown
>(
  variables: AvailableStockQueryVariables,
  options?: UseInfiniteQueryOptions<AvailableStockQueryResponse, TError, TData>
) => {
  return useInfiniteQuery<AvailableStockQueryResponse, TError, TData>(
    ['AvailableStock.infinite', variables],
    (metaData) =>
      fetcher<AvailableStockQueryResponse, AvailableStockQueryVariables>(
        AvailableStockDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteAvailableStockQuery.getKey = (
  variables: AvailableStockQueryVariables
) => ['AvailableStock.infinite', variables];
useAvailableStockQuery.fetcher = (
  variables: AvailableStockQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<AvailableStockQueryResponse, AvailableStockQueryVariables>(
    AvailableStockDocument,
    variables,
    options
  );
