import classNames from 'classnames/bind';
import {
  createElement,
  ReactHTML,
  ReactNode,
  HTMLAttributes,
  forwardRef,
} from 'react';

import type { BackgroundColor, Shadow, Color } from '../types';

import styles from './Box.module.scss';

const cx = classNames.bind(styles);

export type BoxProps = HTMLAttributes<Element> & {
  type?: keyof ReactHTML;
  borderColor?: Color;
  shadow?: Shadow;
  backgroundColor?: BackgroundColor;
  className?: string;
  children?: ReactNode;
};

// eslint-disable-next-line react/display-name
const Box = forwardRef(
  (
    {
      type = 'div',
      borderColor,
      shadow,
      backgroundColor,
      className,
      children,
      ...rest
    }: BoxProps,
    ref
  ) => {
    const containerStyleOption = {
      [`borderColor_${borderColor}`]: borderColor,
      [`shadow_${shadow}`]: shadow,
      [`backgroundColor_${backgroundColor}`]: backgroundColor,
    };

    return createElement(
      type,
      {
        ref,
        className: cx(className, containerStyleOption),
        ...rest,
      },
      children
    );
  }
);

export default Box;
