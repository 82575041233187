import { groupBy } from 'lodash';

import * as CartItemLocalStorage from './CartItemLocalStorage';
import { LocalCartItem } from './CartItemLocalStorage';

import KemiRuntimeError from '@global/service/Error/KemiRuntimeError';
import {
  AddCartItemListMutationResponse,
  AddCartItemListMutationVariables,
  DeleteCartItemListMutationResponse,
  DeleteCartItemListMutationVariables,
  GetCartItemListQueryResponse,
  UpdateCartItemMutationResponse,
  UpdateCartItemMutationVariables,
  UpdateCartItemsCheckedMutationResponse,
  UpdateCartItemsCheckedMutationVariables,
  useGetCartItemInfoQuery,
} from '@pages/cart/source/graphql/Cart.generated';
import { CartItem } from '@schema/types';
import { isObjectEmpty } from '@utils/collection';

export const addCartItemList = async (
  variables: AddCartItemListMutationVariables
): Promise<AddCartItemListMutationResponse> => {
  const items = variables.cartInput.items;
  const newCartItems: LocalCartItem[] = items.map((cartItem) => {
    return {
      ...cartItem,
      id: String(new Date().getTime()),
      createdAt: cartItem.createdAt || new Date(),
      updatedAt: cartItem.createdAt || new Date(),
      checked: !!cartItem.checked,
    };
  });
  const ids = newCartItems.map((item) => item.id);
  CartItemLocalStorage.createItems(newCartItems);
  const { cartItems } = await getCartItemList();
  return Promise.resolve({
    addToCart: {
      totalCount: newCartItems.length,
      items: cartItems?.filter((cartItem) => ids.includes(cartItem.id)),
    },
  });
};

export const updateCartItem = (
  variables: UpdateCartItemMutationVariables
): Promise<UpdateCartItemMutationResponse> => {
  const localItems = CartItemLocalStorage.getItems();
  const item = localItems.find((item) => item.id === variables.id);

  if (!item) {
    throw new KemiRuntimeError({
      code: 'RESOURCE_NOT_FOUND',
      message: `CART_ITEM_NOT_FOUND: ${variables.id}`,
    });
  }

  const newCartItem: LocalCartItem = {
    ...item,
    ...variables.cartItem,
    updatedAt: new Date(),
  };

  CartItemLocalStorage.updateItems([newCartItem]);
  return Promise.resolve({
    updateCartItem: {
      id: variables.id,
    },
  });
};

export const updateCartItemListChecked = (
  variables: UpdateCartItemsCheckedMutationVariables
): Promise<UpdateCartItemsCheckedMutationResponse> => {
  const ids = variables.cartItemsChecked.itemIDs;
  const checked = variables.cartItemsChecked.checked;
  const localItems = CartItemLocalStorage.getItems()
    .filter((item) => ids.includes(item.id))
    .map((item) => {
      item.checked = checked;
      item.updatedAt = new Date();
      return item;
    });
  CartItemLocalStorage.updateItems(localItems);
  return Promise.resolve({
    updateCartItemsChecked: {
      itemIDs: ids,
    },
  });
};

export const getCartItemList =
  async (): Promise<GetCartItemListQueryResponse> => {
    const localItems = CartItemLocalStorage.getItems();

    const cardIds = Array.from(
      new Set(localItems.map((localItem) => localItem.productCardId || ''))
    );

    const cartInfo = await useGetCartItemInfoQuery.fetcher({
      cardIds: cardIds,
    })();
    const { cards } = cartInfo;
    const validCards = cards.filter((card) => !!card);
    const groupByCardId = groupBy(validCards, (card) =>
      isObjectEmpty(card) ? '' : card?.id
    );
    const validLocalItems = localItems.filter(
      (item) => !!groupByCardId[item.productCardId || '']
    );

    const fullyInformedCartItems = validLocalItems.map((localItem) => {
      const cardGroupByItems = groupByCardId[localItem.productCardId || ''];

      if (
        isObjectEmpty(cardGroupByItems[0]) ||
        isObjectEmpty(cardGroupByItems[0]?.productDetail)
      ) {
        throw new KemiRuntimeError({
          code: 'INTERNAL_ERROR',
          message: 'CART_INFO_IS_NULL',
          options: cartInfo,
        });
      }

      return {
        ...localItem,
        createdAt: String(localItem.createdAt),
        updatedAt: String(localItem.updatedAt),
        product: cardGroupByItems[0]?.productDetail,
        productCard: cardGroupByItems[0],
        sellerUser: {
          id: cardGroupByItems[0]?.user?.id || '',
          linkName: cardGroupByItems[0]?.user?.kemi?.linkName || '',
          kemi: cardGroupByItems[0]?.user?.kemi,
        },
      };
    });

    return {
      cartItems: [...fullyInformedCartItems] as CartItem[],
    };
  };

export const deleteCartItemList = (
  variables: DeleteCartItemListMutationVariables
): Promise<DeleteCartItemListMutationResponse> => {
  const deleteItems = CartItemLocalStorage.deleteItems(
    variables.cartInput.itemIDs
  );
  return Promise.resolve({
    removeFromCart: {
      ids: deleteItems.map((cartItem) => cartItem.id),
    },
  });
};
