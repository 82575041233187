import { nanoid } from 'nanoid';
import { Area } from 'react-easy-crop/types';
import Resizer from 'react-image-file-resizer';
import { UAParser } from 'ua-parser-js';

export const copyToClipboard = async (
  targetText: string
): Promise<string | void> => {
  const textArea = document.createElement('textarea');
  textArea.value = targetText;

  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  return new Promise((resolve, reject) => {
    document.execCommand('copy') ? resolve('COPY SUCCESS') : reject();
    textArea.remove();
  });
};

export const wait = (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

export const selectFiles = (
  options?: Partial<{ [T in keyof HTMLInputElement]: HTMLInputElement[T] }>
): Promise<FileList | null> => {
  return new Promise((resolve) => {
    const input = document.createElement('input');
    input.style.display = 'none';
    input.type = 'file';

    Object.assign(input, options);

    document.body.appendChild(input);

    window.addEventListener('focus', openFilePopUp);
    input.addEventListener('change', openFilePopUp);

    input.click();

    async function openFilePopUp(event: Event) {
      window.removeEventListener('focus', openFilePopUp);
      await wait(500);

      if (!input.value) {
        resolve(null);
        return;
      }

      if (event.target === window) {
        return;
      }

      document.body.removeChild(input);
      resolve((event.target as HTMLInputElement).files as FileList);
    }
  });
};

export const selectImage = (
  options?: Partial<{ [T in keyof HTMLInputElement]: HTMLInputElement[T] }>
): Promise<FileList | null> => {
  return new Promise(async (resolve) => {
    const files = await selectFiles({ accept: 'image/*', ...options });
    resolve(files);
  });
};

const REDUCED_IMAGE_DATA = {
  SIZE: 1500,
  ROTATE: 0,
  QUALITY: 100,
};

const downsizeImage = (file: File | Blob): Promise<string> => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      REDUCED_IMAGE_DATA.SIZE,
      REDUCED_IMAGE_DATA.SIZE,
      'PNG',
      REDUCED_IMAGE_DATA.QUALITY,
      REDUCED_IMAGE_DATA.ROTATE,
      (uri) => resolve(uri as string),
      'base64'
    );
  });
};

const convertHEICtoPNG = async (url: string) => {
  const heic2any = require('heic2any');
  const rawFile = await fetch(url);
  const blob = await rawFile.blob();
  const convertedBlob = await heic2any({ blob });
  const resizedImageUri: string = await downsizeImage(convertedBlob);

  return resizedImageUri;
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => resolve(reader.result as string),
      false
    );
    reader.readAsDataURL(file);
  });
};

export const imageFileToBase64 = async (file: File): Promise<string> => {
  let imageUrl = await fileToBase64(file);

  if (file.type === 'image/heic') {
    imageUrl = await convertHEICtoPNG(imageUrl);
  } else {
    imageUrl = await downsizeImage(file);
  }

  return imageUrl;
};

export const loadSdk = <T>(src: string, namespace: string): Promise<T> => {
  return new Promise((resolve, reject) => {
    const js: HTMLScriptElement = document.createElement('script');
    js.src = src;
    js.onload = () => {
      // @ts-ignore
      resolve(window[namespace]);
    };
    js.onerror = (err) => {
      reject(err);
    };
    document.body.append(js);
  });
};

export const getUserAgent = () => {
  const unparsedUserAgent = navigator.userAgent;
  const parser = new UAParser(unparsedUserAgent);
  const _userAgent = {
    browser: parser.getBrowser(),
    device: parser.getDevice(),
    os: parser.getOS(),
  };

  return _userAgent;
};

export const textParser = (str: string) => {
  const parser = new DOMParser();
  const parseResult = parser.parseFromString(str, 'text/html');
  const parsedStr = parseResult.body.textContent;

  return parsedStr || '';
};

export const checkIsMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const hapticVibration = () => {
  // android 환경에서만 실행 가능
  if (window.navigator.vibrate) {
    window.navigator.vibrate(2);
  }
};

export function resizeImage(
  file: File,
  size: { maxHeight: number; maxWidth: number }
): Promise<string> {
  return new Promise((resolve) => {
    // resize하기 전 이미지 size를 구하기 위해 보이지 않는 html img 태그 추가.
    const tempImg = document.createElement('img');
    tempImg.style.display = 'none';
    document.body.appendChild(tempImg);

    // 파일 로드
    const reader = new FileReader();
    reader.onload = handleFileLoad;
    reader.readAsDataURL(file);

    function handleFileLoad(e: ProgressEvent<FileReader>) {
      tempImg.src = (e.target as FileReader).result as string;
      tempImg.onload = function () {
        let width = tempImg.width;
        let height = tempImg.height;

        // 이미지의 비율을 유지하며 resize
        if (width > height) {
          if (width > size.maxWidth) {
            height *= size.maxWidth / width;
            width = size.maxWidth;
          }
        } else {
          if (height > size.maxHeight) {
            width *= size.maxHeight / height;
            height = size.maxHeight;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctx.drawImage(tempImg, 0, 0, width, height);

        const dataurl = canvas.toDataURL(file.type);

        // resize 완료 후 임시로 추가한 tempImg 정리
        document.body.removeChild(tempImg);
        resolve(dataurl);
      };
    }
  });
}

const createImage = (url: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = '';
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });
};

export const base64toFile = (dataurl: string) => {
  const dataArr: string[] = dataurl.split(',');
  const mimeCheck: string[] | null = dataArr[0].match(/:(.*?);/);
  const mime = mimeCheck && mimeCheck.length > 0 && mimeCheck[1];
  const bstr = atob(dataArr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], nanoid(), { type: mime as string });
};

export async function getCroppedImageBase64(imageSrc: string, pixelCrop: Area) {
  const image = (await createImage(imageSrc)) as HTMLImageElement;
  const canvas = document.createElement('canvas');

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  const croppedImageBase64 = canvas.toDataURL('image/png');

  return croppedImageBase64;
}

export const safeJsonParse = (text: string | null | undefined) => {
  if (typeof text === 'string') {
    try {
      return JSON.parse(text);
    } catch (err) {
      return null;
    }
  } else {
    return null;
  }
};

export const selectFilesDataUrl = async (
  options?: Partial<{ [T in keyof HTMLInputElement]: HTMLInputElement[T] }>
): Promise<string[] | undefined> => {
  const files = await selectFiles(options);

  if (!files) return;

  const fileDataUrls = await Promise.all(
    Array.from(files).map(async (file) => {
      return await fileToBase64(file);
    })
  );

  return fileDataUrls;
};

export const isWebView = () => {
  // @ts-ignore
  const standalone = window.navigator.standalone;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios && !standalone && !safari) {
    // iOS webview
    return true;
  }

  // over android 8 version #https://stackoverflow.com/a/61251124
  if (!ios && userAgent.includes('wv')) {
    // Android webview
    return true;
  }

  return false;
};
