import { useRef, useEffect } from 'react';

function useEffectOnce(effectCallback: () => void) {
  const isInvoked = useRef(false);

  useEffect(() => {
    if (!isInvoked.current) {
      isInvoked.current = true;
      effectCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useEffectOnce;
