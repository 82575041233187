import { devtools, persist } from 'zustand/middleware';
import create from 'zustand/vanilla';

type OAuthState = {
  redirectTo?: string;
  linkName?: string;
};

const store = create<OAuthState>()(
  devtools(
    persist(() => ({}), {
      name: 'oauth-state',
      getStorage: () => sessionStorage,
    })
  )
);

export const storeOAuthState = (state: OAuthState) => {
  store.setState(state);
};

export const getOAuthState = () => {
  return store.getState();
};

export const encodeOAuthState = () => {
  return window.btoa(JSON.stringify(store.getState()));
};

export const decodeOAuthState = (encodedState: string) => {
  return JSON.parse(window.atob(encodedState)) as OAuthState;
};
