import { createContext } from 'react';

import { LayoutType } from '@shared/types/app';

type AppContextValue = {
  layoutType: LayoutType;
};

const AppContext = createContext<AppContextValue>({
  layoutType: 'NONE',
});

export default AppContext;
