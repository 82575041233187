import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';

import styles from './LoginAndSignupModal.module.scss';

import Box from '@global/components/atoms/Box';
import Button from '@global/components/atoms/Button';
import Image from '@global/components/atoms/Image';
import Text from '@global/components/atoms/Text';
import { toast } from '@global/components/atoms/ToastContainer';
import FloatModal from '@global/components/molecules/FloatModal';
import KemiLoginModal from '@global/components/organisms/LoginAndSignupModal/KemiLoginModal';
import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import useLoginAndSignupModalStore from '@global/store/loginAndSignupModalStore';
import { LoginModalType, UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import ROUTE from '@routers';
import appIconChrome from '@static/media/app_icon_chrome.png';
import appIconSafari from '@static/media/app_icon_safari.png';
import facebook from '@static/media/facebook.svg';
import google from '@static/media/google.svg';
import kakao from '@static/media/kakao.svg';
import naver from '@static/media/naver.svg';
import { css, styled } from '@styles/stitches.config';
import { copyToClipboard, isWebView } from '@utils/dom';
import { initLoginAndRegisterFlow } from 'src/auth/modules/auth';
import I18n from 'src/i18n/components/I18n';

const cx = classNames.bind(styles);

export const SNS_LOGIN = 0;
export const KEMI_LOGIN = 1;

const LoginAndSignupModal = () => {
  const { type, isOpen, linkName, redirectTo, close, switchModalType } =
    useLoginAndSignupModalStore();
  const [modalStep, setModalStep] = useState(SNS_LOGIN);

  const [webViewGuideModal, setWebViewGuideModal] = useState(false);
  useEffect(() => {
    if (webViewGuideModal) {
      logFirebase(
        UserInteractionType.CLICK,
        EVENT_TAG.LANDING_TOAST.GOOGLE_SIGNIN_TOAST
      );
    }
  }, [webViewGuideModal]);

  const kakaoButtonText = {
    [LoginModalType.login]: i18n.t('k_sign_in_with_kakao'),
    [LoginModalType.signup]: i18n.t('k_start_with_kakao'),
  }[type];
  const naverButtonText = {
    [LoginModalType.login]: i18n.t('k_sign_in_with_naver'),
    [LoginModalType.signup]: i18n.t('k_start_with_naver'),
  }[type];
  const googleButtonText = {
    [LoginModalType.login]: i18n.t('k_sign_in_with_google'),
    [LoginModalType.signup]: i18n.t('k_start_with_google'),
  }[type];
  const facebookButtonText = {
    [LoginModalType.login]: i18n.t('k_sign_in_with_facebook'),
    [LoginModalType.signup]: i18n.t('k_start_with_facebook'),
  }[type];
  const checkMessage = {
    [LoginModalType.login]: i18n.t('k_new_to_kemi'),
    [LoginModalType.signup]: i18n.t('k_already_have_an_account'),
  }[type];
  const switchButtonText = {
    [LoginModalType.login]: i18n.t('k_start_kemi_for_free'),
    [LoginModalType.signup]: i18n.t('k_log_in'),
  }[type];
  const logEvent = (provider: 'KAKAO' | 'NAVER' | 'GOOGLE' | 'FACEBOOK') => {
    logFirebase(
      UserInteractionType.CLICK,
      type === LoginModalType.signup
        ? EVENT_TAG.REGISTER.SIGNUP_CLICK_SIGNUP_START
        : EVENT_TAG.LOGIN.SIGNIN_CLICK_SIGNIN_START,
      { id_provider: provider }
    );
  };
  const handleKakaoLoginClick = async () => {
    logEvent('KAKAO');
    initLoginAndRegisterFlow('kakao', redirectTo, linkName);
  };

  const handleNaverLoginClick = async () => {
    logEvent('NAVER');
    initLoginAndRegisterFlow('naver', redirectTo, linkName);
  };

  const handleGoogleLoginClick = async () => {
    // 웹뷰 브라우저라면 구글 로그인은 허용하지 않음. 별도의 모달을 띄워줘야함.
    if (isWebView()) {
      setWebViewGuideModal(true);
      return;
    }

    logEvent('GOOGLE');
    initLoginAndRegisterFlow('google', redirectTo, linkName);
  };

  const handleFacebookLoginClick = async () => {
    const ua = new UAParser().getUA().toLocaleLowerCase();
    const isAndroid = ua.includes('android');
    const isKakao = ua.includes('kakaotalk');

    if (isWebView() && isAndroid && isKakao) {
      setWebViewGuideModal(true);
      return;
    }

    logEvent('FACEBOOK');
    initLoginAndRegisterFlow('facebook', redirectTo, linkName);
  };
  const handleClose = () => {
    logFirebase(
      UserInteractionType.CLICK,
      type === LoginModalType.signup
        ? EVENT_TAG.REGISTER.SIGNUP_CLICK_CLOSE
        : EVENT_TAG.LOGIN.SIGNIN_CLICK_CLOSE
    );

    if (webViewGuideModal) {
      logFirebase(
        UserInteractionType.CLICK,
        EVENT_TAG.LANDING_TOAST.GOOGLE_SIGNIN_TOAST_CLICK_CLOSE
      );
      setWebViewGuideModal(false);
    }

    close();
  };
  const handleModalType = () => {
    logFirebase(
      UserInteractionType.CLICK,
      type === LoginModalType.signup
        ? EVENT_TAG.REGISTER.SIGNUP_CLICK_GO_TO_SIGNIN
        : EVENT_TAG.LOGIN.SIGNIN_CLICK_GO_TO_SIGNUP
    );
    switchModalType();
  };

  useEffect(() => {
    if (isOpen) {
      logFirebase(
        UserInteractionType.PAGE_IN,
        type === LoginModalType.signup
          ? EVENT_TAG.REGISTER.SCREEN_OPEN_SIGNUP_POPUP
          : EVENT_TAG.LOGIN.SCREEN_OPEN_SIGNIN_POPUP
      );
    }
  }, [type, isOpen]);

  if (modalStep === KEMI_LOGIN) {
    return (
      <KemiLoginModal
        onBackClick={() => setModalStep(SNS_LOGIN)}
        onSignUpClick={() => {
          setModalStep(SNS_LOGIN);
          switchModalType();
        }}
      />
    );
  }

  return (
    <FloatModal isOpen={isOpen} onClose={handleClose} type={'desktop'}>
      {webViewGuideModal ? (
        <GuideModal />
      ) : (
        <Box className={cx('signupModal')}>
          <Button type={'kakaoLogin'} onClick={handleKakaoLoginClick}>
            <Box className={cx('snsButton')}>
              <Image src={kakao} alt={'kakao'} />
              <Text>{kakaoButtonText}</Text>
            </Box>
          </Button>
          <Button type={'naverLogin'} onClick={handleNaverLoginClick}>
            <Box className={cx('snsButton')} id={'naver_id_login'}>
              <Image src={naver} alt={'naver'} />
              <Text color={'white'}>{naverButtonText}</Text>
            </Box>
          </Button>
          <Button type={'googleLogin'} onClick={handleGoogleLoginClick}>
            <Box className={cx('snsButton')}>
              <Image src={google} alt={'google'} />
              <Text>{googleButtonText}</Text>
            </Box>
          </Button>
          <Button type={'googleLogin'} onClick={handleFacebookLoginClick}>
            <Box className={cx('snsButton')}>
              <Image src={facebook} alt={'facebook'} />
              <Text>{facebookButtonText}</Text>
            </Box>
          </Button>
          {type === LoginModalType.signup && (
            <Box className={cx('mt8')}>
              <Text color={'grey100'} font={'captionRegular'}>
                <I18n
                  i18nKey={'k_by_signing_up_you_agree_v2'}
                  i18nOptions={{
                    a1attr: `href="${
                      ROUTE.privacy
                    }" target="_blank" class="${cx(
                      'termsAndPrivate',
                      termsAndPrivateWordCss()
                    )}"`,
                    a2attr: `href="${ROUTE.terms}" target="_blank" class="${cx(
                      'termsAndPrivate',
                      termsAndPrivateWordCss()
                    )}"`,
                  }}
                />
              </Text>
            </Box>
          )}
          {type === LoginModalType.login && (
            <KemiLoginButton
              onClick={() => {
                setModalStep(KEMI_LOGIN);
                logFirebase(
                  UserInteractionType.CLICK,
                  EVENT_TAG.LOGIN.KEMI_ID_LOGIN_CLICK
                );
              }}
            >
              <Text font={'bodyBold'} color={'grey100'}>
                {i18n.t('k_sign_in_with_kemi')}
              </Text>
            </KemiLoginButton>
          )}
          <Box className={cx('mt24')}>
            <Text font={'bodyRegular'}>{checkMessage}</Text>
          </Box>
          <Button
            type={'line'}
            onClick={handleModalType}
            className={cx('mt24')}
          >
            {switchButtonText}
          </Button>
        </Box>
      )}
    </FloatModal>
  );
};

const GuideModal = () => {
  const onClickCopy = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.LANDING_TOAST.GOOGLE_SIGNIN_TOAST_CLICK_CLIPBOARD
    );
    copyToClipboard(location.href);
    toast.good(i18n.t('k_the_address_has_been_saved_to_the_clipboard'));
  };

  return (
    <div className={cx('guideModal')}>
      <div className={cx('text')}>
        <Text font={'bodyBold'}>{i18n.t('k_web_view_guide_message_head')}</Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Text onClick={onClickCopy}>
          {'👆' + i18n.t('k_copy_to_clipboard') + '👆'}
        </Text>
      </div>
      <div className={cx('iconBox')}>
        <div className={cx('browserIcon')}>
          <Image
            src={appIconChrome}
            width={96}
            height={96}
            objectFit={'contain'}
            alt={'app-icon'}
          />
          <Text font={'bodyBold'} className={cx('iconText')}>
            {i18n.t('k_chrome')}
          </Text>
        </div>
        <div className={cx('browserIcon')}>
          <Image
            src={appIconSafari}
            width={96}
            height={96}
            objectFit={'contain'}
            alt={'app-icon'}
          />
          <Text font={'bodyBold'} className={cx('iconText')}>
            {i18n.t('k_safari')}
          </Text>
        </div>
      </div>
      <div className={cx('text')}>
        <Text font={'captionRegular'}>
          {i18n.t('k_web_view_guide_message_caption')}
        </Text>
      </div>
    </div>
  );
};

const KemiLoginButton = styled('div', {
  marginTop: 24,
  cursor: 'pointer',
});

const termsAndPrivateWordCss = css({
  fontType: 'captionBold',
});

export default LoginAndSignupModal;
