import { isEqual } from 'lodash';

import { useLogoutMutation } from '../graphql/auth.generated';
import { OAuthModule, OAuthProviderType } from '../types';

import facebookOAuth from './facebook';
import googleOAuth from './google';
import kakaoOAuth from './kakao';
import { kemiAuth as kemiAuthModule } from './kemi';
import { clearToken, storeToken } from './kemiToken';
import naverOAuth from './naver';
import {
  decodeOAuthState,
  encodeOAuthState,
  getOAuthState,
  storeOAuthState,
} from './oAuthState';

import { EVENT_TAG } from '@global/constants';
import KemiApiError from '@global/service/Error/KemiApiError';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';

const oAuthModuleMap = new Map<OAuthProviderType, OAuthModule>([
  ['kakao', kakaoOAuth],
  ['naver', naverOAuth],
  ['facebook', facebookOAuth],
  ['google', googleOAuth],
]);

/**
 * 로그인, 회원가입 플로우 시작
 *
 * 각 OAuth 제공업체의 authorize 플로우를 시작한다.
 *
 * @param provider - OAuth 제공업체
 * @param redirectTo - 로그인, 회원가입 플로우 이후 이동할 url
 * @param linkName - 회원가입에 사용할 케미 링크네임
 */
export const initLoginAndRegisterFlow = async (
  provider: OAuthProviderType,
  redirectTo?: string,
  linkName?: string
) => {
  const oAuthModule = oAuthModuleMap.get(provider);

  if (!oAuthModule) {
    throw new Error('invalid oauth provider');
  }

  storeOAuthState({ redirectTo, linkName });

  const encodedState = encodeOAuthState();

  oAuthModule.authorize(encodedState);
};

/**
 * Kemi 자체 로그인 콜백
 *
 * @param id
 * @param password
 * @param redirectTo
 */
export const handleKemiAuthCallback = async (
  id: string,
  password: string,
  redirectTo?: string
) => {
  const { accessToken, refreshToken } = await kemiAuthModule.issueKemiToken({
    loginId: id,
    password,
  });

  storeToken(accessToken, refreshToken || '');
  location.href = redirectTo ?? '/';
};

/**
 * OAuth 콜백을 처리한다.
 *
 * sns access token을 발급하고, 케미 토큰을 발급한다.
 *
 * @param provider - OAuth 제공업체
 * @param code - authorization code
 * @param state - OAuth state
 */
export const handleOAuthCallback = async (
  provider: OAuthProviderType,
  code: string,
  state: string
) => {
  const oAuthModule = oAuthModuleMap.get(provider);

  if (!oAuthModule) {
    throw new Error('invalid oauth provider');
  }

  if (!isValidOAuthCallback(state)) {
    throw new Error('invalid oauth callback');
  }

  const snsAccessToken = await oAuthModule.requestIssuingSnsAccessToken(code);

  const { accessToken, refreshToken } = await issueKemiToken(
    oAuthModule,
    snsAccessToken,
    provider
  );

  storeToken(accessToken, refreshToken);

  /**
   * next/router 대신 location.href로 리디렉트해 어플리케이션을 강제로 초기화한다.
   * 로그인 후, react query 등의 캐시를 직접 제어해야하는 로직을 놓침에 발생 할 수 있는 오류를 회피하기 위함.
   */
  const authState = getOAuthState();
  const redirectTo = authState.redirectTo;
  location.href = redirectTo ?? '/';
};

const isValidOAuthCallback = (callbackState: string) => {
  const decodedCallbackState = decodeOAuthState(callbackState);
  const storedState = getOAuthState();
  return isEqual(decodedCallbackState, storedState);
};

const issueKemiToken = async (
  oAuth: OAuthModule,
  snsAccessToken: string,
  provider: OAuthProviderType
) => {
  try {
    const kemiToken = await oAuth.issueKemiToken(snsAccessToken);
    return kemiToken;
  } catch (e) {
    if (e instanceof KemiApiError && e.code === 'NOT_REGISTERED_USER') {
      const { linkName } = getOAuthState();
      await oAuth.registerKemi(snsAccessToken, linkName);

      logFirebase(UserInteractionType.NONE, EVENT_TAG.REGISTER.SIGNUP_DONE, {
        id_provider: provider.toUpperCase(),
      });

      const kemiToken = await oAuth.issueKemiToken(snsAccessToken);
      return kemiToken;
    }

    throw e;
  }
};

/**
 * sns access token 발급
 *
 * @param provider - OAuth 제공업체
 * @param code - OAuth authorization code
 * @returns sns access token
 */
export const issueSnsAccessToken = async (
  provider: OAuthProviderType,
  code: string
) => {
  const oAuthModule = oAuthModuleMap.get(provider);

  if (!oAuthModule) {
    throw new Error('invalid oauth provider');
  }

  const snsAccessToken = await oAuthModule.issueSnsAccessTokenOnServer(code);
  return snsAccessToken;
};

/**
 * 로그아웃
 */
export const logout = async () => {
  await useLogoutMutation.fetcher()();
  clearToken();
  location.href = '/';
};
