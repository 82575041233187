import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEn from './en/strings.json';
import translationId from './id/strings.json';
import translationKo from './ko/strings.json';
import translationPseudo from './pseudo/strings.json';

import { I18nKey } from '@i18n/types.generated';
import {
  IS_NOT_PROD,
  LIVE_TRANSLATION_MODE,
} from '@shared/components/ThirdPartyScripts';

export const PSEUDO_TEXT = 'pseudo'; // ach
const I18N_DEFAULT_LANGUAGE = 'en';

const resource = {
  en: {
    translation: translationEn,
  },
  id: {
    translation: translationId,
  },
  ko: {
    translation: translationKo,
  },
  [PSEUDO_TEXT]: {
    translation: translationPseudo,
  },
};

let userLanguage = I18N_DEFAULT_LANGUAGE;

if (IS_NOT_PROD && LIVE_TRANSLATION_MODE) {
  // 크라우딘 in-context 모드
  userLanguage = PSEUDO_TEXT;
} else if (typeof window !== 'undefined') {
  userLanguage = navigator.language;
}

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resource,
    lng: userLanguage,
    fallbackLng: I18N_DEFAULT_LANGUAGE,
    debug: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      prefix: '{',
      suffix: '}',
      escapeValue: false, // react already safes from xss
    },
  });

const i18n = {
  t: (key: I18nKey, interpolation?: { [k in string]: any }) => {
    return i18next.t(key, interpolation);
  },
};

export default i18n;
