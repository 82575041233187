import * as Types from 'schema/types';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type MeForStoreQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeForStoreQueryResponse = {
  me?: {
    id: string;
    linkName: string;
    loginType?: Types.ELoginType | null;
    createdAt: string;
    userProfile?: {
      name?: string | null;
      phoneNumber?: string | null;
      email?: string | null;
      gender?: Types.EGender | null;
      birth?: string | null;
      allowMarketingNotification: boolean;
      address?: {
        zipCode?: string | null;
        default?: string | null;
        detailed?: string | null;
      } | null;
    } | null;
    kemi: {
      id: string;
      title: string;
      profileImageUrl?: string | null;
      coverImageUrl?: string | null;
      sns: Array<{ type: Types.ESnsType; link: string }>;
    };
    sellerInfo?: {
      grade?: number | null;
      sns?: { type?: Types.ESellerSnsType | null; url?: string | null } | null;
    } | null;
  } | null;
};

export const MeForStoreDocument = `
    query MeForStore {
  me {
    id
    linkName
    loginType
    userProfile {
      name
      phoneNumber
      email
      gender
      birth
      address {
        zipCode
        default
        detailed
      }
      allowMarketingNotification
    }
    kemi {
      id
      title
      profileImageUrl
      coverImageUrl
      sns {
        type
        link
      }
    }
    sellerInfo {
      sns {
        type
        url
      }
      grade
    }
    createdAt
  }
}
    `;
export const useMeForStoreQuery = <
  TData = MeForStoreQueryResponse,
  TError = unknown
>(
  variables?: MeForStoreQueryVariables,
  options?: UseQueryOptions<MeForStoreQueryResponse, TError, TData>
) =>
  useQuery<MeForStoreQueryResponse, TError, TData>(
    variables === undefined ? ['MeForStore'] : ['MeForStore', variables],
    fetcher<MeForStoreQueryResponse, MeForStoreQueryVariables>(
      MeForStoreDocument,
      variables
    ),
    options
  );

useMeForStoreQuery.getKey = (variables?: MeForStoreQueryVariables) =>
  variables === undefined ? ['MeForStore'] : ['MeForStore', variables];
export const useInfiniteMeForStoreQuery = <
  TData = MeForStoreQueryResponse,
  TError = unknown
>(
  variables?: MeForStoreQueryVariables,
  options?: UseInfiniteQueryOptions<MeForStoreQueryResponse, TError, TData>
) => {
  return useInfiniteQuery<MeForStoreQueryResponse, TError, TData>(
    variables === undefined
      ? ['MeForStore.infinite']
      : ['MeForStore.infinite', variables],
    (metaData) =>
      fetcher<MeForStoreQueryResponse, MeForStoreQueryVariables>(
        MeForStoreDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteMeForStoreQuery.getKey = (variables?: MeForStoreQueryVariables) =>
  variables === undefined
    ? ['MeForStore.infinite']
    : ['MeForStore.infinite', variables];
useMeForStoreQuery.fetcher = (
  variables?: MeForStoreQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<MeForStoreQueryResponse, MeForStoreQueryVariables>(
    MeForStoreDocument,
    variables,
    options
  );
