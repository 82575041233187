import { QueryClient } from '@tanstack/react-query';
import { useState } from 'react';

const useKemiQueryClient = () => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            onSuccess: async () => {
              await queryClient.invalidateQueries();
            },
          },
        },
      })
  );

  return queryClient;
};

export default useKemiQueryClient;
