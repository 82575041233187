import { NextRouter, useRouter } from 'next/router';
import { useEffect } from 'react';

import { EVENT_TAG } from '@global/constants';
import useUserQuery from '@global/queries/useUserQuery';
import ChannelTalk from '@global/service/ChannelTalk';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import useChannelTalkOptionStore from '@pages/app/store/useChannelTalkOptionStore';
import ROUTE from '@routers';
import zIndex from '@styles/zIndex.module.scss';

// 채널톡 초기화가 필요한 페이지 라우트를 추가해주세요
const ALLOWED_PATH = new Set([
  ROUTE.mykemi.index,
  ROUTE.mykemi.home,
  ROUTE.commissions.index,
  ROUTE.products.product,
  ROUTE.orders.index,
  ROUTE.orders.checkout.index,
  ROUTE.debugMode,
]);

// 채널톡 버튼을 숨겨야 하는 경우 추가해주세요
const HIDE_BUTTON_PATH = new Set([
  ROUTE.orders.checkout.index,
  ROUTE.debugMode,
]);

// 채널톡 초기화 시점에 memberId를 함께 초기화해주시려면 분기점을 추가해주세요
const getMemberId = (
  router: NextRouter,
  user: ReturnType<typeof useUserQuery>['data']
) => {
  if (user) {
    return user.userId;
  }

  return router.pathname === ROUTE.orders.checkout.index
    ? (router.query.orderId as string)
    : undefined;
};

const pluginKey = process.env.NEXT_PUBLIC_CHANNEL_TALK_PLUGIN_KEY as string;

function useChannelTalkBoot() {
  const router = useRouter();
  const { hideChannelTalkButton } = useChannelTalkOptionStore();

  const { data: user, isLoading: isUserLoading } = useUserQuery();

  useEffect(() => {
    if (isUserLoading) {
      return;
    }

    const memberId = user?.userId;
    const profile = user
      ? {
          email: user.profile.email,
          mobileNumber: user.profile.phoneNumber,
          name: user.profile.name,
          isKemiUser: true,
          kemiName: user.title,
          kemiLinkName: user.linkName,
          mktAgree: user.profile.allowMarketingNotification,
        }
      : undefined;

    ChannelTalk.boot({
      pluginKey,
      zIndex: Number(zIndex.channelTalkZIndex),
      memberId,
      profile,
    });

    if (router.pathname === ROUTE.orders.index) {
      ChannelTalk.onShowMessenger(() => {
        logFirebase(
          UserInteractionType.CLICK,
          EVENT_TAG.KEMI_ORDERDETAIL.KEMI_ORDERDETAIL_CLICK_INQUIRY
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoading, user]);

  useEffect(
    function bootAllowedPath() {
      if (isUserLoading) {
        return;
      }

      const showChannelButtonOnBoot =
        ALLOWED_PATH.has(router.pathname) && !hideChannelTalkButton;

      if (showChannelButtonOnBoot) {
        ChannelTalk.boot({
          pluginKey,
          zIndex: Number(zIndex.channelTalkZIndex),
          hideChannelButtonOnBoot: HIDE_BUTTON_PATH.has(router.pathname),
          memberId: getMemberId(router, user),
        });
        ChannelTalk.showChannelButton();
      } else {
        ChannelTalk.hideChannelButton();
      }
    },

    [router.pathname, hideChannelTalkButton, isUserLoading, user, router]
  );
}

export default useChannelTalkBoot;
