import classNames from 'classnames';
import React from 'react';

import styles from './Button.module.scss';

import { ButtonProps } from '@global/components/atoms/Button/Button';

export type FormButtonProps = Exclude<
  React.HTMLProps<HTMLInputElement>,
  'type'
> & {
  buttonType?: ButtonProps['type'];
  type?: 'button' | 'submit' | 'reset';
};

const FormButton = ({
  buttonType = 'default',
  disabled,
  className,
  type,
  ...props
}: FormButtonProps) => {
  return (
    <input
      type={type || 'submit'}
      className={classNames(styles[buttonType], className)}
      disabled={buttonType === 'disabled' || disabled}
      {...props}
    />
  );
};

export default FormButton;
