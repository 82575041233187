import i18n from '@i18n/index';
import { EGender, ELoginType } from '@schema/types';

const getSnsLoginType = (code?: ELoginType | null) => {
  if (!code) {
    return '-';
  }

  switch (code) {
    case 'KAKAO':
      return i18n.t('k_kakao');
    case 'NAVER':
      return i18n.t('k_naver');
    case 'GOOGLE':
      return i18n.t('k_google');
    case 'FACEBOOK':
      return i18n.t('k_facebook');
    case 'KEMI':
      return i18n.t('k_kemi');
    default:
      return i18n.t('k_login_type_etc');
  }
};

const getGender = (code?: EGender | 'NONE' | null) => {
  if (!code) {
    return '-';
  }

  switch (code) {
    case 'FEMALE':
      return i18n.t('k_female');
    case 'MALE':
      return i18n.t('k_male');
    default:
      return '-';
  }
};

const translator = {
  getSnsLoginType,
  getGender,
};

export default translator;
