import KemiError from './KemiError';

type EKemiRuntimeErrorCode =
  | 'ILLEGAL_ARGUMENT_ERROR'
  | 'INVALID_RUNNING_ENVIRONMENT'
  | 'INVALID_PARAMETER'
  | 'INVALID_VALUE'
  | 'INVALID_INTERFACE'
  | 'RESOURCE_NOT_FOUND'
  | 'INTERNAL_ERROR'
  | 'LOGIN_TOKEN_NOT_NULLABLE'
  | 'SNS_TOKEN_NOT_NULLABLE'
  | 'NOT_NULLABLE'
  | 'NOT_IMPLEMENT'
  | 'FORBIDDEN'
  | 'PAY_FAILED'
  | 'FACEBOOK_TOKEN_EXPIRED';

export type KemiRuntimeErrorParam = {
  message: string;
  code: EKemiRuntimeErrorCode;
  options?: any;
};

class KemiRuntimeError extends KemiError {
  name: string;
  code: EKemiRuntimeErrorCode;
  options: any;

  constructor(param: KemiRuntimeErrorParam) {
    super(param.message);
    this.name = 'KemiRuntimeError';
    this.code = param.code;
    this.options = param.options;
  }

  toString() {
    return `[${this.name}]${this.code}::${this.message}`;
  }
}

export const kemiRuntimeErrorHandler = (error: KemiRuntimeError) => {
  return error;
};

export default KemiRuntimeError;
