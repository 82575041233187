import { useRouter } from 'next/router';

import useUserQuery from '@global/queries/useUserQuery';
import i18n from '@i18n/index';
import SideMenu from '@pages/app/components/SideMenu';
import {
  CommissionDashboard,
  DashboardContainer,
  KemiDashboard,
  SellerCenterDashboard,
} from '@pages/app/components/SideMenuDashboard';
import useGetSelectedMenu from '@pages/app/hooks/useGetSelectedMenu';
import { styled } from '@styles/stitches.config';

const KemiPlaygroundDesktopSideMenu = () => {
  const router = useRouter();
  const activeMenu = useGetSelectedMenu(router.pathname);
  const { data: user } = useUserQuery();
  const isSeller = user?.sellerInfo?.isSeller;

  if (user) {
    return (
      <Container>
        {activeMenu === 'KEMI' && (
          <DesktopDashboard>
            <KemiDashboard />
          </DesktopDashboard>
        )}
        {activeMenu === 'SELLER_CENTER' && (
          <div>
            <DesktopDashboard>
              <SellerCenterDashboard user={user} />
            </DesktopDashboard>
            {!isSeller && (
              <GuideText>
                {i18n.t(
                  'k_this_is_an_example_to_help_you_understand_register_as_a_seller_and_check_for_yourself'
                )}
              </GuideText>
            )}
          </div>
        )}
        {activeMenu === 'COMMISSIONS' && (
          <DesktopDashboard>
            <CommissionDashboard user={user} />
          </DesktopDashboard>
        )}
        <SideMenu activeMenu={activeMenu || 'KEMI'} />
      </Container>
    );
  }

  return (
    <Container>
      <SideMenu activeMenu={activeMenu || 'KEMI'} />
    </Container>
  );
};

const Container = styled('div', {
  gridRowWithGap: 24,
});

const DesktopDashboard = styled(DashboardContainer, {
  '& > *': {
    flexBasis: 180,
    flexGrow: 1,
    boxSizing: 'border-box',
  },
});

const GuideText = styled('div', {
  marginTop: 8,
  color: '$grey100',
  fontType: 'captionRegular',
});

export default KemiPlaygroundDesktopSideMenu;
