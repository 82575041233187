import React, { PropsWithChildren } from 'react';

import KemiLayout from '@pages/app/components/KemiLayout';
import KemiPlaygroundDesktopHeader from '@pages/app/components/KemiPlaygroundDesktopHeader';
import KemiPlaygroundDesktopSideMenu from '@pages/app/components/KemiPlaygroundDesktopSideMenu';
import useUserAgent from '@shared/hooks/useUserAgent';
import { styled } from '@styles/stitches.config';

type DesktopContentMode = 'DESKTOP' | 'MOBILE';

const KemiPlaygroundLayout = ({
  desktopContentMode,
  children,
}: PropsWithChildren<{ desktopContentMode: DesktopContentMode }>) => {
  const { isDesktop } = useUserAgent();

  if (!isDesktop) {
    return <KemiLayout>{children}</KemiLayout>;
  }

  return (
    <LayoutContainer>
      <HeaderContainer>
        <KemiPlaygroundDesktopHeader />
      </HeaderContainer>
      <Content>
        <SideMenu>
          <KemiPlaygroundDesktopSideMenu />
        </SideMenu>
        <ContentWrapper pcViewContent={desktopContentMode}>
          {children}
        </ContentWrapper>
      </Content>
    </LayoutContainer>
  );
};

const LayoutContainer = styled('div', {
  minHeight: 'inherit',
  display: 'grid',
  gridTemplateRows: '$headerHeight 1fr',
  width: '100%',
});

const HeaderContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  position: 'sticky',
  top: 0,
  background: '$white',
  zIndex: '$header',
  borderBottom: '1px solid $grey50',
});

const SideMenu = styled('div', {
  position: 'sticky',
  top: '$sizes$headerHeight',
  height: '$contentHeight',
  overflowY: 'scroll',
  padding: 16,
});

const Content = styled('div', {
  gridColumnWithGap: 0,
  justifyContent: 'center',
  gridTemplateColumns: 'minmax(240px, 400px) minmax(800px, 1200px)',
});

const ContentWrapper = styled('div', {
  position: 'relative',
  borderLeft: '1px solid $grey50',
  variants: {
    pcViewContent: {
      MOBILE: {
        width: 400,
      },
      DESKTOP: {},
    },
  },
});

export default KemiPlaygroundLayout;
