import React, { useEffect, useState } from 'react';

import { handleKemiAuthCallback } from '../../../../auth/modules/auth';

import Button from '@global/components/atoms/Button';
import Text from '@global/components/atoms/Text';
import FloatModal from '@global/components/molecules/FloatModal';
import LabelInput from '@global/components/molecules/LabelInput';
import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import useLoginAndSignupModalStore from '@global/store/loginAndSignupModalStore';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import ArrowLeft from '@static/svg/ArrowLeft';
import { styled } from '@styles/stitches.config';

type Props = {
  onBackClick: () => void;
  onSignUpClick: () => void;
};

const KemiLoginModal = ({ onBackClick, onSignUpClick }: Props) => {
  const { isOpen, redirectTo } = useLoginAndSignupModalStore();
  const [isValidForm, setIsValidForm] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    return () => {
      setId('');
      setPassword('');
    };
  }, []);

  useEffect(() => {
    setIsValidForm(!!id && !!password);
    setHasError(false);
  }, [id, password]);

  const handleLoginClick = async () => {
    try {
      logFirebase(
        UserInteractionType.NONE,
        EVENT_TAG.LOGIN.SIGNIN_CLICK_SIGNIN_START,
        {
          id_provider: 'KEMI',
        }
      );

      await handleKemiAuthCallback(id, password, redirectTo);
    } catch (e) {
      setHasError(true);
      setIsValidForm(false);
    }
  };

  return (
    <FloatModal isOpen={isOpen} type={'desktop'} showLabel={false}>
      <LoginFormContainer>
        <ArrowLeftButton onClick={onBackClick} />
        <Text type={'div'} font={'titleBold'}>
          {i18n.t('k_kemi_login_modal_title')}
        </Text>
        <LoginFormContent
          onSubmit={(e) => {
            e.preventDefault();
            handleLoginClick();
          }}
        >
          <IdPasswordForm>
            <LabelInput
              value={id}
              placeholder={i18n.t('k_id')}
              onChange={setId}
              onBlur={() =>
                logFirebase(
                  UserInteractionType.BLUR,
                  EVENT_TAG.LOGIN.KEMI_ID_INPUT_FOCUS_OUT,
                  {
                    id,
                  }
                )
              }
            />
            <LabelInput
              type={'password'}
              value={password}
              placeholder={i18n.t('k_password')}
              onChange={setPassword}
            />
            {hasError && (
              <Text color={'red100'} font={'captionRegular'}>
                {i18n.t('k_kemi_login_modal_login_error')}
              </Text>
            )}
          </IdPasswordForm>
          <LoginButton type={'submit'} disabled={!isValidForm}>
            {i18n.t('k_log_in')}
          </LoginButton>
          <LoginGuide>
            <Text font={'captionRegular'}>
              {i18n.t(
                'k_kemi_login_modal_new_accounts_are_not_supported_guide'
              )}
            </Text>
          </LoginGuide>
          <SignUpButton type={'lineNoShadow'} onClick={onSignUpClick}>
            {i18n.t('k_kemi_login_modal_signup_sns')}
          </SignUpButton>
        </LoginFormContent>
      </LoginFormContainer>
    </FloatModal>
  );
};

const ArrowLeftButton = styled(ArrowLeft, {
  cursor: 'pointer',
});

const LoginFormContainer = styled('div', {
  paddingTop: 16,
  display: 'grid',
  gridAutoFlow: 'row',
  alignItems: 'start',
  justifyContent: 'flex-start',
  gap: 24,
  textAlign: 'center',
  gridTemplateColumns: '1fr',
});

const LoginFormContent = styled('form', {});

const IdPasswordForm = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  alignItems: 'start',
  justifyContent: 'flex-start',
  gridTemplateColumns: '1fr',
  gap: 8,
  textAlign: 'left',
});

const LoginButton = styled('button', {
  marginTop: 16,
  width: '100%',
  height: 48,
  paddingY: 16,
  border: '1px solid $black',
  borderRadius: 8,
  backgroundColor: '$white',
  color: '$black',
  fontType: 'bodyBold',
  cursor: 'pointer',
  '&:disabled': {
    border: 'none',
    backgroundColor: '$grey50',
    color: '$white',
  },
});

const LoginGuide = styled('div', {
  marginTop: 40,
  background: '$purple10',
  borderRadius: 8,
  padding: '16px 12px',
  wordBreak: 'keep-all',
});

const SignUpButton = styled(Button, {
  marginTop: 16,
});

export default KemiLoginModal;
