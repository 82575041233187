import { SVGProps } from 'react';

import colors from '@styles/color.module.scss';

type CloseProps = {
  inverse?: boolean;
} & SVGProps<SVGSVGElement>;

const Close = ({ inverse, ...rest }: CloseProps) => {
  const white = colors['white'];
  const black = colors['black'];
  const color = inverse ? white : black;

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={'M6 18L18 6'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M18 18L6 6'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default Close;
