import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';

import {
  GetCartItemListQueryResponse,
  useGetCartItemListQuery,
} from '../graphql/Cart.generated';

import useUserQuery from '@global/queries/useUserQuery';
import { getCartItemList } from '@pages/cart/source/NonMemberCartService';

type QueryOption = UseQueryOptions<GetCartItemListQueryResponse>;

export const useGetCartItemList = (options?: QueryOption) => {
  const { data: user } = useUserQuery();
  const isLogin = !!user;
  const apiQueryResult = useGetCartItemListQuery(
    {},
    { enabled: isLogin, ...options }
  );
  const localStorageQueryResult = useLocalStorageQuery({
    enabled: !isLogin,
    ...options,
  });
  return isLogin ? apiQueryResult : localStorageQueryResult;
};

const useLocalStorageQuery = (options?: QueryOption) =>
  useQuery<GetCartItemListQueryResponse>(
    useGetCartItemListQuery.getKey(),
    getCartItemList,
    options
  );
